import { ArrowDownward, ArrowUpward, Delete } from "@mui/icons-material";
import { FunctionComponent } from "react";
import { MoveQuestionHandlerFunction } from "../../../interfaces/PropTypes";

interface QuestionHeaderPropsType {
	color: string;
	order: number;
	currentActive: number;
	moveQuestionHandler: MoveQuestionHandlerFunction;
	deleteQSHandler: (index: number, nestedIndex: number) => void;
}

const QuestionHeader: FunctionComponent<QuestionHeaderPropsType> = ({
	color,
	order,
	moveQuestionHandler,
	deleteQSHandler,
	currentActive,
}) => {
	return (
		<div className="question-header" style={{ backgroundColor: color + "FF" }}>
			<div className="question-title">QUESTION {currentActive}</div>
			<div className="question-actions">
				<ArrowUpward
					className="question-action disabled"
					onClick={() => moveQuestionHandler(order, -1, "up")}
				/>
				<ArrowDownward
					className="question-action"
					onClick={() => moveQuestionHandler(order, -1, "down")}
				/>
				<Delete
					className="question-action"
					onClick={() => deleteQSHandler(order, -1)}
				/>
			</div>
		</div>
	);
};

export default QuestionHeader;
