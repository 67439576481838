import * as React from "react";
import CircularProgress, {
	CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";

function CircularProgressWithLabel(
	props: CircularProgressProps & { value: number }
) {
	return (
		<Box sx={{ position: "relative", display: "inline-flex" }}>
			<CircularProgress
				variant="determinate"
				sx={{
					color: "#c5c5c5",
				}}
				size={96}
				thickness={5}
				{...props}
				value={100}
			/>
			<CircularProgress
				variant="indeterminate"
				sx={{
					color: "black",
					borderRadius: "5px",
					animationDuration: "2000ms",
					position: "absolute",
					left: 0,
					strokeLinecap: "round",
				}}
				size={96}
				thickness={5}
				{...props}
			/>
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: "absolute",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}>
				<Typography
					variant="caption"
					component="div"
					fontSize="32px"
					fontWeight={700}
					color="#6B6B6B">{`${props.value}`}</Typography>
			</Box>
		</Box>
	);
}

export default function PbWithTime({ time }: { time: number }) {
	const [progress, setProgress] = useState<number>(time);

	useEffect(() => {
		const timer = setInterval(() => {
			setProgress((prevProgress) => (prevProgress <= 0 ? 0 : --prevProgress));
		}, 1000);
		return () => {
			clearInterval(timer);
		};
	});

	return <CircularProgressWithLabel value={progress} />;
}
