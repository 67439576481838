import { FunctionComponent } from "react";
import StepContainer from "../../partials/StepContainer";

interface JoinGameHeaderPropsType {
	gameTitle: string | undefined;
	gettingCode: boolean;
	gettingNickName: boolean;
	waitingForOthers: boolean;
	backgroundColor?: string;
}

const JoinGameHeader: FunctionComponent<JoinGameHeaderPropsType> = ({
	gameTitle,
	gettingCode,
	gettingNickName,
	waitingForOthers,
	backgroundColor,
}) => {
	function stepCounter(): number {
		return (
			(gettingCode && 1) ||
			(gettingNickName && 2) ||
			(waitingForOthers && 3) ||
			1
		);
	}

	const getBackgroundColor = () => {
		return (
			((gettingNickName || waitingForOthers) && backgroundColor) || "#0071b3"
		);
	};

	return (
		<div
			className="join-game-header d-flex flex-column justify-content-between"
			style={{ backgroundColor: getBackgroundColor() }}>
			<div className="d-flex flex-grow-1 flex-column justify-content-center text-container">
				<div className={`title`}>{gameTitle || `${"  "}`}</div>
				<div className="guide">
					{(gettingCode && "Enter the Game Pin to join the game") ||
						(gettingNickName && "Enter a nickName") ||
						(waitingForOthers && "You are in!")}
				</div>
				<div className="guide-detail">
					{(gettingCode && "It’s on the quiz master’s screen.") ||
						(gettingNickName &&
							"It’ll appear on the quiz master’s screen and leaderboard.") ||
						(waitingForOthers &&
							"Waiting for the quiz master to start the game...")}
				</div>
			</div>
			<StepContainer count={3} currentActive={stepCounter()} />
		</div>
	);
};

export default JoinGameHeader;
