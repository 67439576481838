import { FunctionComponent, useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Message from "../../layout/message/Message";
import GameHeader from "./GameHeader";
import "../MainScreen.scss";
import PbGeneral from "../../partials/progressbar/PbGeneral";
import { WebSocketContext } from "../../../context/WebSocketContext";
import { useAppDispatch, useAppSelector } from "../../../services/store/hooks";
import {
	getGame,
	resetGamePlayers,
} from "../../../services/store/features/gameSlice";
import { CircularProgress, Collapse } from "@mui/material";
import {
	resetPlayerSlice,
	savePlayers,
} from "../../../services/store/features/playerSlice";
import PinCodeContainer from "./PinCodeContainer";
import PbWithTime from "../../partials/progressbar/PbWithTime";
import { GamePlayer } from "../../../interfaces/player.interface";
import { TransitionGroup } from "react-transition-group";

interface MainScreenPropsType {}

const MainScreen: FunctionComponent<MainScreenPropsType> = () => {
	let location = useLocation();
	let query = new URLSearchParams(location.search);
	const isRestarted: boolean =
		Boolean(query.get("isRestarted")) && query.get("isRestarted") === "yes";
	const [error, setError] = useState<boolean>(false);
	const [joining, setJoining] = useState<boolean>(true);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const { code } = useParams<{ code: string }>();
	const socket = useContext(WebSocketContext)!;
	const history = useHistory();

	const { config, game, player } = useAppSelector((state) => state);
	const { allPlayers } = player;
	const dispatch = useAppDispatch();

	async function loadGame(signal: AbortSignal) {
		try {
			dispatch(resetPlayerSlice());
			if (!isRestarted) {
				await dispatch(
					resetGamePlayers({
						token: config.token,
						magazineId: config.magazine.magazineId,
						code: code ?? "",
						signal,
					})
				);
			}
			setError(false);
			setErrorMessage("");
		} catch (error) {
			setError(true);
			setErrorMessage(`load game failed, error: ${error}`);
		}
	}

	// handlers
	const handleGameStart = (event: React.MouseEvent<HTMLButtonElement>) => {
		try {
			event.preventDefault();
			setError(false);
			setErrorMessage("");
			setJoining(false);
			socket.emit("onStartGame", {
				message: "game started",
				totalCount: allPlayers.length,
				gameCode: code,
			});
			setTimeout(() => {
				history.push(
					`/master/screen/${code}?token=${config.token}&magazine=${config.magazine.magazineId}`
				);
			}, 5000);
		} catch (error) {
			setError(true);
			setErrorMessage(`start game event failed, error: ${error}`);
		}
	};

	useEffect(() => {
		socket.on("connect", () => {
			console.log("Connected...");
		});
		socket.on(
			"addPlayerToGame",
			(data: { players: GamePlayer[]; gameCode: string; sid: string }) => {
				if (data.gameCode !== code) {
					return;
				}
				if (joining) {
					dispatch(savePlayers(data.players));
				} else {
					socket.emit("onRejectJoin", {
						message: "game already started",
						gameCode: code,
						sid: data.sid,
					});
				}
			}
		);
		return () => {
			console.log("unregistering the event...");
			socket.off("addPlayerToGame");
			socket.off("connect");
		};
	}, [socket, joining]);

	useEffect(() => {
		if (!isRestarted) {
			const controller = new AbortController();
			loadGame(controller.signal);
			return () => {
				controller.abort();
			};
		}
	}, [code]);

	if (error) {
		return <Message type="error" text={errorMessage} />;
	}

	if (!game || game.isGameLoading) {
		return <CircularProgress />;
	}

	return (
		<div className="main-screen row">
			<div className="left col-9 px-0">
				<GameHeader
					name={game.game?.quiz.quizName}
					color={(!joining && "#f57200") || game.game?.quiz.quizColor}
				/>
				<div
					className={`main-content d-flex flex-column align-items-center ${
						joining && "starting"
					}`}
					style={{
						background:
							(!joining && "#f57200E8") || game.game!.quiz.quizColor + "E8",
					}}>
					{joining && (
						<>
							<PinCodeContainer handleGameStart={handleGameStart} />
						</>
					)}
					{!joining && (
						<>
							<div className="ready-text">Get Ready</div>
						</>
					)}
				</div>
			</div>
			<div className="right col-3">
				{joining && (
					<>
						<div className="timer-right">
							<PbGeneral />
						</div>
					</>
				)}
				{!joining && (
					<>
						<div className="timer-right">
							<PbWithTime time={5} />
						</div>
					</>
				)}
				<div className="main-content">
					<TransitionGroup>
						{allPlayers &&
							allPlayers.map((player, index) => (
								<Collapse key={index} timeout={index === 0 ? 500 : 0}>
									<div className="name" key={index}>
										{player.nickName}
									</div>
								</Collapse>
							))}
					</TransitionGroup>
				</div>
				<div className="footer w-100 d-flex justify-content-center align-items-center">
					{allPlayers.length} player{allPlayers.length > 1 && "s"}
				</div>
			</div>
		</div>
	);
};

export default MainScreen;
