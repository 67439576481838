import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GameInterface } from "../../../../../shared/interfaces/game-interface.interface";
import * as GameApi from "../../GameApi";

interface GameStateType {
	game: GameInterface | null;
	isGameLoading: boolean;
}

const initialState: GameStateType = {
	game: null,
	isGameLoading: true,
};

export const getGame = createAsyncThunk(
	"game/getGame",
	async (
		{
			token,
			magazineId,
			code,
			signal,
		}: {
			token: string;
			magazineId: string;
			code: string;
			signal?: AbortSignal;
		},
		thunkAPI
	) => {
		const response = await GameApi.getGame(token, magazineId, code, signal);
		return response;
	}
);

export const resetGamePlayers = createAsyncThunk(
	"game/resetGamePlayers",
	async (
		{
			token,
			magazineId,
			code,
			signal,
		}: {
			token: string;
			magazineId: string;
			code: string;
			signal?: AbortSignal;
		},
		thunkAPI
	) => {
		const response = await GameApi.resetGamePlayers(
			token,
			magazineId,
			code,
			signal
		);
		return response;
	}
);

export const gameSlice = createSlice({
	name: "game",
	initialState,
	reducers: {
		resetGameSlice(state) {
			state = initialState;
		},
		resetPlayersArray(state) {
			if (state.game) {
				state.game.players = [];
			}
		},
	},
	extraReducers(builder) {
		builder.addCase(getGame.fulfilled, (state, action) => {
			if (action.payload) {
				state.game = action.payload;
				const pageTitle = action.payload.quiz?.quizName || "Quiz";
				document.title = pageTitle;
				state.isGameLoading = false;
			}
		});
		builder.addCase(getGame.pending, (state) => {
			state.isGameLoading = true;
		});
		builder.addCase(getGame.rejected, (state, action) => {
			throw new Error(action.error.message);
		});
		builder.addCase(resetGamePlayers.fulfilled, (state, action) => {
			if (action.payload) {
				state.game = action.payload;
			}
			state.isGameLoading = false;
		});
		builder.addCase(resetGamePlayers.pending, (state) => {
			state.isGameLoading = true;
		});
		builder.addCase(resetGamePlayers.rejected, (state, action) => {
			throw new Error(action.payload as string);
		});
	},
});

export const { resetGameSlice, resetPlayersArray } = gameSlice.actions;

export default gameSlice.reducer;
