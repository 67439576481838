import { ArrowDownward, ArrowUpward, Delete } from "@mui/icons-material";
import { TransitionGroup } from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {
	Checkbox,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import { FunctionComponent } from "react";
import {
	AddNestedQHandlerFunction,
	HandleNestedCorrectAnswerFunction,
	HandleNestedQuestionSelectorsFunction,
	MoveQuestionHandlerFunction,
	SectionInputHandlerFunction,
} from "../../../interfaces/PropTypes";
import { Section } from "../../../interfaces/quiz.interface";
import AddNestedQuestion from "./AddNestedQuestion";
import defaultSettings from "../../../interfaces/defaultSettings";

export interface NestedQuestionsCardPropsType {
	s: Section;
	moveQuestionHandler: MoveQuestionHandlerFunction;
	order: number;
	currentActive: number;
	deleteQSHandler: (index: number, nestedIndex: number) => void;
	sectionInputHandler: SectionInputHandlerFunction;
	handleNestedQuestionSelectors: HandleNestedQuestionSelectorsFunction;
	handleNestedCorrectAnswer: HandleNestedCorrectAnswerFunction;
	addNestedQHandler: AddNestedQHandlerFunction;
}

const NestedQuestionsCard: FunctionComponent<NestedQuestionsCardPropsType> = ({
	s,
	moveQuestionHandler,
	order,
	sectionInputHandler,
	handleNestedCorrectAnswer,
	handleNestedQuestionSelectors,
	deleteQSHandler,
	addNestedQHandler,
	currentActive,
}) => {
	return (
		<div
			className="question-answers conatiner my-0"
			style={{
				backgroundColor: s.sectionColor + "CC",
			}}>
			<AddNestedQuestion
				addNestedQHandler={addNestedQHandler}
				sectionIndex={order}
				quesitonIndex={0}
				textColor={s.sectionColor}
			/>
			{s.questions && (
				<TransitionGroup>
					{s.questions.map((question, i) => {
						return (
							<Collapse className="" key={"nested" + i}>
								<div className="question-container nested">
									<div
										className="question-header"
										style={{ backgroundColor: s.sectionColor + "FF" }}>
										<div className="question-title">
											QUESTION {currentActive + i}
										</div>
										<div className="question-actions">
											<ArrowUpward
												className="question-action disabled"
												onClick={() => moveQuestionHandler(i, order, "up")}
											/>
											<ArrowDownward
												className="question-action"
												onClick={() => moveQuestionHandler(i, order, "down")}
											/>
											<Delete
												className="question-action"
												onClick={() => deleteQSHandler(order, i)}
											/>
										</div>
									</div>
									<div
										className="question-settings"
										style={{ backgroundColor: s.sectionColor + "A1" }}>
										<div className="sub-container w-100">
											<div className="name">
												<TextField
													className="custom-text-field w-100"
													required
													label="Question"
													value={question.question}
													onChange={(event) =>
														sectionInputHandler(event, "question", order, i, -1)
													}
													inputProps={{
														maxLength: defaultSettings.questionLength,
													}}
												/>
												<FormHelperText className="d-flex justify-content-end px-2">
													{question.question.length || 0}/
													{defaultSettings.questionLength}
												</FormHelperText>
											</div>
											<div className="time">
												<FormControl
													fullWidth
													className="custom-text-field w-100">
													<InputLabel id="demo-simple-select-label-1">
														Time before answer
													</InputLabel>
													<Select
														labelId="demo-simple-select-label"
														id="demo-simple-select-1"
														value={question.questionTime}
														label="Time before answer"
														onChange={(event) =>
															handleNestedQuestionSelectors(
																event,
																"questionTime",
																order,
																i
															)
														}>
														<MenuItem value={5}>5 Seconds</MenuItem>
														<MenuItem value={10}>10 Seconds</MenuItem>
														<MenuItem value={15}>15 Seconds</MenuItem>
														<MenuItem value={20}>20 Seconds</MenuItem>
														<MenuItem value={30}>30 Seconds</MenuItem>
													</Select>
												</FormControl>
											</div>
										</div>
										<FormControl className="w-100">
											<TextField
												className="custom-text-field"
												label="Media url"
												value={question.mediaUrl}
												onChange={(event) =>
													sectionInputHandler(event, "mediaUrl", order, i, -1)
												}
											/>
											<FormHelperText>
												Picture (JPG or PNG), video (Youtube or Vimeo), audio
												(MP3).
											</FormHelperText>
										</FormControl>
									</div>
									<div
										className="question-answers conatiner"
										style={{ backgroundColor: s.sectionColor + "63" }}>
										<div className="d-flex mb-3 align-items-center justify-content-between">
											<div className="answer-settings flex-grow-1">
												<FormControl
													className="custom-text-field"
													sx={{ width: "30%", marginRight: "24px" }}>
													<InputLabel id="demo-simple-select-label-2">
														Number of answers
													</InputLabel>
													<Select
														labelId="demo-simple-select-label-2"
														id="demo-simple-select"
														label="Number of answers"
														value={question.numAnswers}
														onChange={(event) =>
															handleNestedQuestionSelectors(
																event,
																"numAnswers",
																order,
																i
															)
														}>
														<MenuItem value={2}>2</MenuItem>
														<MenuItem value={4}>4</MenuItem>
													</Select>
												</FormControl>
												<FormControl
													className="custom-text-field"
													sx={{ width: "30%" }}>
													<InputLabel id="demo-simple-select-label-2">
														Time to answer
													</InputLabel>
													<Select
														labelId="demo-simple-select-label-2"
														id="demo-simple-select"
														label="Time to answer"
														value={question.answerTime}
														onChange={(event) =>
															handleNestedQuestionSelectors(
																event,
																"answerTime",
																order,
																i
															)
														}>
														<MenuItem value={5}>5 Seconds</MenuItem>
														<MenuItem value={10}>10 Seconds</MenuItem>
														<MenuItem value={15}>15 Seconds</MenuItem>
														<MenuItem value={20}>20 Seconds</MenuItem>
														<MenuItem value={30}>30 Seconds</MenuItem>
													</Select>
												</FormControl>
											</div>
											<div className="correct-answer flex-shrink-0 text-center">
												CORRECT <br /> ANSWER
											</div>
										</div>
										{question.answers &&
											question.answers.map((e1, i1) => (
												<div
													className="d-flex mb-3 align-items-center justify-content-between"
													key={i1}>
													<div className="flex-grow-1">
														<TextField
															className="custom-text-field w-100"
															required
															label={`Answer ${i1 + 1}`}
															value={e1.answer}
															sx={{
																input: {
																	color: "#ffffff",
																},
															}}
															onChange={(event) =>
																sectionInputHandler(
																	event,
																	"answer",
																	order,
																	i,
																	i1
																)
															}
															focused
															inputProps={{
																maxLength: defaultSettings.answerLength,
															}}
														/>
														<FormHelperText className="d-flex justify-content-end px-2">
															{e1.answer.length || 0}/
															{defaultSettings.answerLength}
														</FormHelperText>
													</div>
													<div className="flex-shrink-0 text-center checkbox mb-4 d-flex justify-content-end">
														<Checkbox
															checked={e1.correct}
															onChange={() =>
																handleNestedCorrectAnswer(order, i, i1)
															}
															sx={{
																color: "#ffffff",
																"&.Mui-checked": {
																	color: "#ffffff",
																},
															}}
														/>
													</div>
												</div>
											))}
									</div>
								</div>
								<AddNestedQuestion
									addNestedQHandler={addNestedQHandler}
									sectionIndex={order}
									quesitonIndex={i + 1}
									textColor={s.sectionColor}
								/>
							</Collapse>
						);
					})}
				</TransitionGroup>
			)}
		</div>
	);
};

export default NestedQuestionsCard;
