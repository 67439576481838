import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { MagazineInterface } from "../../../interfaces/magazine.interface";
import * as Api from "../../Api";

interface ConfigStateType {
	magazine: MagazineInterface;
	loading: boolean;
	token: string;
	lang: string;
	queryString: string;
}

const initialState: ConfigStateType = {
	loading: true,
	magazine: {
		clientName: "",
		publicKey: "",
		magazineId: "",
		config: {
			server: {
				HOST: "",
			},
			client: {
				universalLink: "https://fcm.orteccloud.com/c/10272?title=Quiz_Player",
			},
		},
	},
	token: "",
	lang: "en",
	queryString: "",
};

export const getConfig = createAsyncThunk(
	"config/getConfig",
	async (
		{
			token,
			magazineId,
			signal,
		}: {
			token: string;
			magazineId: string;
			signal: AbortSignal;
		},
		thunkAPI
	) => {
		const response = await Api.getMagazine(token, magazineId, signal);
		return response;
	}
);

export const configSlice = createSlice({
	name: "config",
	initialState,
	reducers: {
		saveToken(state, action) {
			state.token = action.payload;
		},
		saveQueryString(state, action) {
			state.queryString = action.payload;
		},
		saveLang(state, action) {
			state.token = action.payload;
		},
	},
	extraReducers(builder) {
		builder.addCase(getConfig.fulfilled, (state, action) => {
			const newMagazineConfig = action.payload;
			if (!newMagazineConfig.config) {
				newMagazineConfig.config = {
					server: {
						HOST: "",
					},
					client: {
						universalLink:
							"https://fcm.orteccloud.com/c/10272?title=Quiz_Player",
					},
				};
			}
			state.magazine = newMagazineConfig;
			state.loading = false;
		});
		builder.addCase(getConfig.pending, (state) => {
			state.magazine = initialState.magazine;
			state.loading = true;
		});
		builder.addCase(getConfig.rejected, (state, action) => {
			state.magazine = initialState.magazine;
			state.loading = false;
		});
	},
});

export const { saveToken, saveQueryString, saveLang } = configSlice.actions;

export default configSlice.reducer;
