import { bottomNavigationActionClasses, Button } from "@mui/material";
import { indicator } from "ordinal";
import { FunctionComponent, MouseEvent } from "react";
import StartGameButton from "../../partials/StartGameButton";

interface LeaderboardPlayerPropsType {
	order: number;
	total: number;
	handleNewGame: (restartQuery: string) => void;
}

const LeaderboardPlayer: FunctionComponent<LeaderboardPlayerPropsType> = ({
	order,
	total,
	handleNewGame,
}) => {
	return (
		<div
			className={`main-content leader-board-player flex-grow-1 d-flex justify-content-center align-items-center`}>
			<div className="remove-scroll-bar h-100">
				<div className="leader-board p-3 d-flex flex-column my-2">
					<div className="rank">
						<div className="text">Rank</div>
						<div className="number">
							{order}
							<sup>{indicator(order)}</sup>
						</div>
					</div>
					<div className="rank">
						<div className="text">Points</div>
						<div className="number">{total}</div>
					</div>
					<Button
						variant="contained"
						sx={{
							background: "white",
							marginBottom: "10px",
							color: "#0071B3",
							"&:hover": {
								backgroundColor: "white",
							},
						}}
						className="new-game-btn m-3"
						onClick={() => handleNewGame("")}>
						NEW GAME
					</Button>
				</div>
			</div>
		</div>
	);
};

export default LeaderboardPlayer;
