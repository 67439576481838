import { Button } from "@mui/material";
import { FunctionComponent } from "react";
import { AddQSHandlerFunction } from "../../../interfaces/PropTypes";

export interface AddQSPropsType {
	order: number;
	addQSHandler: AddQSHandlerFunction;
}

const AddQS: FunctionComponent<AddQSPropsType> = ({ order, addQSHandler }) => {
	return (
		<div className="add-buttons-container mmd">
			<Button
				variant="contained"
				className="add-button"
				onClick={(event) => addQSHandler(event, order, "question")}>
				ADD QUESTION
			</Button>
			<div className="separator-text">or</div>
			<Button
				variant="outlined"
				className="add-button"
				onClick={(event) => addQSHandler(event, order, "section")}>
				ADD SECTION
			</Button>
		</div>
	);
};

export default AddQS;
