import { FunctionComponent } from "react";
import { Answered } from "../../../interfaces/quiz.interface";
import { useAppSelector } from "../../../services/store/hooks";

interface ResultContainerPropsType {
	answered: Answered;
	answersCount: number;
}

const ResultContainer: FunctionComponent<ResultContainerPropsType> = ({
	answered,
	answersCount,
}) => {
	const quiz = useAppSelector((state) => state.game.game?.quiz);
	const base = Math.floor(240 / Math.max(...Object.values(answered)));
	return (
		<div className="results d-flex align-self-center align-items-end">
			{["1", "2", "3", "4"].splice(0, answersCount).map((i) => {
				// @ts-ignore
				const color: string = quiz[`answer_${i}_Color`] || "#fa3854";
				// @ts-ignore
				const singleCount = answered[i];
				return (
					<div className="result" key={i}>
						<div className="count text-center" style={{ color: color }}>
							{singleCount}
						</div>
						<div className="chart">
							<div
								className="body"
								style={{
									background: color + "CC",
									height: base * singleCount + "px",
								}}></div>
							<div
								className="order-container d-flex justify-content-center align-items-center"
								style={{ background: color + "E8" }}>
								<div
									className="order d-flex justify-content-center align-items-center"
									style={{ background: color }}>
									{i}
								</div>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default ResultContainer;
