import { FunctionComponent } from "react";
import { Question, Section } from "../../../interfaces/quiz.interface";
import { useAppSelector } from "../../../services/store/hooks";
import { countQuestions, currentActive } from "../../utils";

interface QuestionContainerPropsType {
	currentQuestion: Question;
	parentIndex: number;
	childIndex: number;
	showingSection: boolean;
	flexGrow?: string;
}

const QuestionContainer: FunctionComponent<QuestionContainerPropsType> = ({
	currentQuestion,
	parentIndex,
	childIndex,
	showingSection,
	flexGrow,
}) => {
	const { game } = useAppSelector((state) => state.game);
	const questions = game?.quiz.questions;
	const isSection = questions?.[parentIndex].type === "section";

	return (
		<div className={`main-content d-flex flex-column ${flexGrow || ""}`}>
			<div className="question-wrapper d-flex align-items-center remove-scroll-bar">
				<div className="w-100">
					{(showingSection && (
						<div className="section-text text-center">
							{isSection && (questions?.[parentIndex] as Section).sectionName}
						</div>
					)) || (
						<>
							<div className="progress-text text-left">
								Question {currentActive(parentIndex, childIndex, questions)}/
								{countQuestions(questions)}
							</div>
							<div className="question-text">{currentQuestion.question}</div>
							{currentQuestion.mediaUrl && (
								<img
									alt={`question - ${currentActive(
										parentIndex,
										childIndex,
										questions
									)}`}
									src={currentQuestion.mediaUrl}
								/>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default QuestionContainer;
