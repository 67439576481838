import {
	FormControl,
	FormControlLabel,
	InputAdornment,
	TextField,
	Switch,
	SelectChangeEvent,
	Divider,
	Button,
	Alert,
	Snackbar,
} from "@mui/material";
import React, {
	ChangeEvent,
	FormEvent,
	FunctionComponent,
	useEffect,
	useState,
} from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import QbHeader from "./QbHeader";
import "./QuizBuilder.scss";
import * as Api from "../../services/Api";
import ColorPicker from "../partials/ColorPicker";
import * as CP from "react-color";
import ShowAnswerSelector from "./ShowAnswerSelector";
import {
	Question,
	Questions,
	Quiz,
	Section,
} from "../../interfaces/quiz.interface";
import QuestionCard from "./question/QuestionCard";
import AddQS from "./question/AddQS";
import {
	defaultQuestion,
	defaultSection,
} from "../../interfaces/default-values";

import { TransitionGroup } from "react-transition-group";
import { Collapse } from "@mui/material";
import SectionCard from "./section/SectionCard";
import CustomModal from "../partials/CustomModal";
import VectorDown from "../partials/vectors/vector-down";
import { useAppSelector } from "../../services/store/hooks";
import { currentActive } from "../utils";

interface QuizBuilderPropsType {}

const QuizBuilder: FunctionComponent<QuizBuilderPropsType> = () => {
	const { _id } = useParams<{ _id: string }>();
	let location = useLocation();
	let query = new URLSearchParams(location.search);
	const token = query.get("token");
	const magazineId = query.get("magazine");
	const [error, setError] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");

	const [toggleQCP, setToggleQCP] = useState<boolean>(false);

	const [quizName, setQuizName] = useState<string>("");
	const [quizColor, setQuizColor] = useState<string>("#0071B3");
	const [showanswers, setShowanswers] = useState<boolean>(true);
	const [keepscore, setKeepscore] = useState<boolean>(true);
	const [showleaderboard, setShowleaderboard] = useState("after-all");

	const [answer_1_Color, seta1c] = useState<string>("#fa3845");
	const [answer_2_Color, seta2c] = useState<string>("#f57200");
	const [answer_3_Color, seta3c] = useState<string>("#2861ff");
	const [answer_4_Color, seta4c] = useState<string>("#e606c0");
	const [answer_1_CP, seta1cp] = useState<boolean>(false);
	const [answer_2_CP, seta2cp] = useState<boolean>(false);
	const [answer_3_CP, seta3cp] = useState<boolean>(false);
	const [answer_4_CP, seta4cp] = useState<boolean>(false);

	const [questions, setQuestions] = useState<Questions>([]);

	const [success, setSuccess] = useState<boolean>(false);
	const [successMessage, setSuccessMessage] = useState<string>("");
	const [modalCOpen, setModalCOpen] = useState<boolean>(false);
	const [modalSOpen, setModalSOpen] = useState<boolean>(false);
	const config = useAppSelector((state) => state.config);

	const history = useHistory();

	async function getQuiz(signal: AbortSignal) {
		try {
			const quiz = await Api.getQuizById(
				token ?? "",
				magazineId ?? "",
				_id,
				signal
			);
			if (!quiz) return;
			setQuizName(quiz.quizName);
			setQuizColor(quiz.quizColor);
			setShowanswers(quiz.showanswers);
			setKeepscore(quiz.keepscore);
			setShowleaderboard(quiz.showleaderboard);
			seta1c(quiz.answer_1_Color);
			seta2c(quiz.answer_2_Color);
			seta3c(quiz.answer_3_Color);
			seta4c(quiz.answer_4_Color);
			setQuestions([...quiz.questions]);
			setError(false);
			setErrorMessage("");
		} catch (error) {
			setError(true);
			setErrorMessage("couldn't get quiz");
		}
	}

	const handleQuizNameInput = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		event.preventDefault();
		const newQuizName = event.currentTarget?.value;
		setQuizName(newQuizName);
	};

	const handleQuizColor = (color: CP.ColorResult) => {
		setQuizColor(color.hex);
		setToggleQCP(false);
	};

	const handleShowLeaderSelect = (event: SelectChangeEvent) => {
		setShowleaderboard(event.target.value);
	};

	// left container handlers
	const addQSHandler = (
		event: React.MouseEvent,
		order: number,
		type: string
	) => {
		event.preventDefault();
		const defaultQuestionJson = JSON.stringify(defaultQuestion);
		const defaultSectionJson = JSON.stringify(defaultSection);
		const data = [...questions];
		if (type === "question") {
			data.splice(order, 0, JSON.parse(defaultQuestionJson));
			setQuestions(data);
		}
		if (type === "section") {
			data.splice(order, 0, JSON.parse(defaultSectionJson));
			setQuestions(data);
		}
	};

	const addNestedQHandler = (
		event: React.MouseEvent,
		sectionIndex: number,
		questionIndex: number
	) => {
		event.preventDefault();
		const defaultQuestionJson = JSON.stringify(defaultQuestion);
		const data = [...questions];
		(data[sectionIndex] as Section).questions.splice(
			questionIndex,
			0,
			JSON.parse(defaultQuestionJson)
		);
		setQuestions(data);
	};

	const moveQuestionHandler = (
		index: number,
		parentIndex: number,
		direction: string
	) => {
		const data = [...questions];
		if (direction === "up") {
			if (index === 0) return;
			if (parentIndex === -1) {
				const prev = data[index - 1];
				data[index - 1] = data[index];
				data[index] = prev;
			} else {
				const prev = (data[parentIndex] as Section).questions[index - 1];
				(data[parentIndex] as Section).questions[index - 1] = (
					data[parentIndex] as Section
				).questions[index];
				(data[parentIndex] as Section).questions[index] = prev;
			}
		} else {
			if (parentIndex === -1) {
				if (index === data.length - 1) return;
				const next = data[index + 1];
				data[index + 1] = data[index];
				data[index] = next;
			} else {
				if (index === (data[parentIndex] as Section).questions.length - 1)
					return;
				const next = (data[parentIndex] as Section).questions[index + 1];
				(data[parentIndex] as Section).questions[index + 1] = (
					data[parentIndex] as Section
				).questions[index];
				(data[parentIndex] as Section).questions[index] = next;
			}
		}
		setQuestions(data);
	};

	// question card
	const questionSettingsHandler = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		target: string,
		index: number,
		answerIndex: number
	) => {
		const data = [...questions];
		if (answerIndex === -1) {
			const newObject = {
				...(data[index] as Question),
				[target]: event.currentTarget?.value,
			};
			data[index] = newObject;
		} else {
			const newAnswer = {
				...(data[index] as Question).answers[answerIndex],
				answer: event.currentTarget?.value,
			};
			(data[index] as Question).answers[answerIndex] = newAnswer;
		}
		setQuestions(data);
	};

	const deleteQSHandler = (index: number, nestedIndex: number) => {
		const data = [...questions];

		if (nestedIndex === -1) {
			data.splice(index, 1);
		} else {
			(data[index] as Section).questions.splice(nestedIndex, 1);
		}
		setQuestions(data);
	};

	const handleCorrectAnswer = (questionIndex: number, answerIndex: number) => {
		const data = [...questions];
		(data[questionIndex] as Question).answers[answerIndex].correct = !(
			data[questionIndex] as Question
		).answers[answerIndex].correct;
		setQuestions(data);
	};
	const handleNestedCorrectAnswer = (
		sectionIndex: number,
		questionIndex: number,
		answerIndex: number
	) => {
		const data = [...questions];
		(data[sectionIndex] as Section).questions[questionIndex].answers[
			answerIndex
		].correct = !(data[sectionIndex] as Section).questions[questionIndex]
			.answers[answerIndex].correct;
		setQuestions(data);
	};
	// end question card

	const handleQSSelectors = (
		event: SelectChangeEvent<number>,
		target: string,
		index: number
	) => {
		const value = event.target.value;
		const data = [...questions];
		const newQuestion = { ...data[index], [target]: value };
		data[index] = newQuestion;
		if (target === "numAnswers") {
			const twoAnswers = (data[index] as Question).answers.splice(0, 2);
			if (value == 2) {
				(data[index] as Question).answers = twoAnswers;
			} else {
				(data[index] as Question).answers = [
					...twoAnswers,
					{ answer: "", correct: false },
					{ answer: "", correct: false },
				];
			}
		}
		setQuestions(data);
	};

	const sectioninputHandler = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		target: string,
		index: number,
		questionIndex: number,
		answerIndex: number
	) => {
		const data = [...questions];
		if (answerIndex === -1) {
			const newObject = {
				...(data[index] as Section).questions[questionIndex],
				[target]: event.currentTarget?.value,
			};
			(data[index] as Section).questions[questionIndex] = newObject;
			setQuestions(data);
			return;
		}
		const newObject = {
			...(data[index] as Section).questions[questionIndex].answers[answerIndex],
			[target]: event.currentTarget?.value,
		};
		(data[index] as Section).questions[questionIndex].answers[answerIndex] =
			newObject;

		setQuestions(data);
	};

	const handleSectionColor = (color: CP.ColorResult, order: number) => {
		const data = [...questions];
		(data[order] as Section).sectionColor = color.hex;
		setQuestions(data);
	};

	const handleNestedQuestionSelectors = (
		event: SelectChangeEvent<number>,
		target: string,
		index: number,
		questionIndex: number
	) => {
		const value = event.target.value;
		const data = [...questions];
		const newQuestion = {
			...(data[index] as Section).questions[questionIndex],
			[target]: value,
		};
		(data[index] as Section).questions[questionIndex] = newQuestion;
		if (target === "numAnswers") {
			const twoAnswers = { ...(data[index] as Section) }.questions[
				questionIndex
			].answers.splice(0, 2);
			if (value == 2) {
				({ ...(data[index] as Section) }.questions[questionIndex].answers =
					twoAnswers);
			} else {
				({ ...(data[index] as Section) }.questions[questionIndex].answers = [
					...twoAnswers,
					{ answer: "", correct: false },
					{ answer: "", correct: false },
				]);
			}
		}
		setQuestions(data);
	};

	const handleFormSubmit = async (event: FormEvent) => {
		event.preventDefault();
		try {
			const quiz: Quiz = {
				magazineId: parseInt(magazineId!) || 0,
				quizName,
				quizColor,
				showanswers,
				keepscore,
				showleaderboard,
				answer_1_Color,
				answer_2_Color,
				answer_3_Color,
				answer_4_Color,
				questions,
			};
			if (_id) {
				await Api.updateQuizById(token ?? "", magazineId ?? "", _id, quiz);
			} else {
				await Api.createQuiz(token ?? "", magazineId ?? "", quiz);
			}
			setSuccess(true);
			setSuccessMessage("your quiz has been saved");
			history.push(`/dashboard${config.queryString}}`);
			setTimeout(() => {
				setSuccess(false);
				setSuccessMessage("");
			}, 5000);
		} catch (error) {
			setError(true);
			setErrorMessage(`quiz has not been saved, error: ${error}`);
		}
	};

	useEffect(() => {
		const controller = new AbortController();
		if (_id) {
			getQuiz(controller.signal);
			return () => {
				controller.abort();
			};
		}
		return () => {
			controller.abort();
		};
	}, [_id, token, magazineId]);

	return (
		<div id="quiz-builder" className="container-fluid">
			<form onSubmit={handleFormSubmit}>
				<div className="row">
					<div className="left col-8">
						<QbHeader title="Quiz builder" />
						<AddQS order={0} addQSHandler={addQSHandler} />
						<TransitionGroup timeout={500}>
							{questions.map((e, i) => {
								return (
									<Collapse key={i}>
										<>
											<div className="">
												{e.type === "question" && (
													<QuestionCard
														order={i}
														currentActive={currentActive(i, 0, questions)}
														color={quizColor}
														question={e as Question}
														moveQuestionHandler={moveQuestionHandler}
														deleteQSHandler={deleteQSHandler}
														questionSettingsHandler={questionSettingsHandler}
														handleQSSelectors={handleQSSelectors}
														handleCorrectAnswer={handleCorrectAnswer}
													/>
												)}
												{e.type === "section" && (
													<SectionCard
														s={e as Section}
														order={i}
														currentActive={currentActive(i, 0, questions)}
														deleteQSHandler={deleteQSHandler}
														moveQuestionHandler={moveQuestionHandler}
														questionSettingsHandler={questionSettingsHandler}
														handleSectionColor={handleSectionColor}
														handleQSSelectors={handleQSSelectors}
														sectionInputHandler={sectioninputHandler}
														handleNestedQuestionSelectors={
															handleNestedQuestionSelectors
														}
														handleNestedCorrectAnswer={
															handleNestedCorrectAnswer
														}
														addNestedQHandler={addNestedQHandler}
													/>
												)}
											</div>
											<AddQS order={i + 1} addQSHandler={addQSHandler} />
										</>
									</Collapse>
								);
							})}
						</TransitionGroup>
					</div>
					<div className="right col-4">
						<div className="settings">
							<QbHeader title="Settings" />
							<TextField
								required
								label="Quiz name"
								variant="outlined"
								value={quizName}
								onChange={handleQuizNameInput}
								className="quiz-name"
							/>
							<div className="quiz-color-container">
								<TextField
									label="Quiz background color"
									className="text-primary disableb-color mt-3"
									id="outlined-start-adornment"
									value={quizColor}
									sx={{ input: { color: quizColor } }}
									InputProps={{
										endAdornment: (
											<InputAdornment
												className="toggle-color-picker h-100"
												position="end"
												onClick={() => setToggleQCP((p) => !p)}>
												<VectorDown variant="black" />
											</InputAdornment>
										),
									}}
								/>
								{toggleQCP && (
									<ColorPicker
										handleColorChange={handleQuizColor}
										onMouseleave={() => setToggleQCP(false)}
									/>
								)}
							</div>

							<FormControl className="mt-2 p-1">
								<FormControlLabel
									control={
										<Switch
											checked={showanswers}
											onChange={() => setShowanswers((p) => !p)}
											name="showanswers"
										/>
									}
									label="Show full answers in the app"
								/>
								<FormControlLabel
									className="my-0 py-0"
									control={
										<Switch
											checked={keepscore}
											onChange={() => setKeepscore((p) => !p)}
											name="keepscore"
										/>
									}
									label="Game with score"
								/>
							</FormControl>

							<ShowAnswerSelector
								value={showleaderboard}
								handleShowAnswersSelect={handleShowLeaderSelect}
							/>

							<Divider className="my-3" sx={{ backgroundColor: "#d6d6d6" }} />

							{[
								{
									c1: answer_1_Color,
									s1: seta1c,
									c2: answer_1_CP,
									s2: seta1cp,
								},
								{
									c1: answer_2_Color,
									s1: seta2c,
									c2: answer_2_CP,
									s2: seta2cp,
								},
								{
									c1: answer_3_Color,
									s1: seta3c,
									c2: answer_3_CP,
									s2: seta3cp,
								},
								{
									c1: answer_4_Color,
									s1: seta4c,
									c2: answer_4_CP,
									s2: seta4cp,
								},
							].map((e, i) => {
								return (
									<div
										className="answer-color-container"
										key={i}
										style={{ backgroundColor: String(e.c1) }}>
										<TextField
											label={`Answer ${i + 1} background color`}
											className="text-primary disableb-color mt-4 custom-text-field"
											id="outlined-start-adornment"
											value={e.c1}
											sx={{
												input: {
													color: "white",
													border: "rgba(255, 255, 255, 0.7)",
												},
												label: { color: "rgba(255, 255, 255, 0.7)" },
											}}
											InputProps={{
												endAdornment: (
													<InputAdornment
														className="toggle-color-picker h-100"
														position="end"
														onClick={() => e.s2((p) => !p)}>
														<VectorDown variant="white" />
													</InputAdornment>
												),
											}}
										/>
										{e.c2 && (
											<ColorPicker
												handleColorChange={(color) => {
													e.s1(color.hex);
													e.s2(false);
												}}
												onMouseleave={() => e.s2(false)}
											/>
										)}
									</div>
								);
							})}
						</div>
						<div className="submit-group">
							<Button
								className="submit-action"
								variant="outlined"
								onClick={(event) => {
									event.preventDefault();
									setModalCOpen(true);
								}}>
								CANCEL
							</Button>
							<Button
								className="submit-action"
								type="submit"
								variant="contained">
								SAVE QUIZ
							</Button>
						</div>
						{modalCOpen && (
							<CustomModal
								open={modalCOpen}
								handleClose={() => setModalCOpen(false)}
								handleSubmit={() =>
									history.push(`/dashboard${config.queryString}`)
								}
								text="your changes will not be saved"
								action="Cancel"
							/>
						)}
					</div>
				</div>
			</form>
			<Snackbar open={success}>
				<Alert security="info" className="text-success w-100 p-4">
					{successMessage}
				</Alert>
			</Snackbar>
		</div>
	);
};

export default QuizBuilder;
