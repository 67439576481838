import { FunctionComponent } from "react";

interface VectorNextPropsType {
	variant: string;
	size: [number, number];
}

const VectorNext: FunctionComponent<VectorNextPropsType> = ({
	variant,
	size,
}) => {
	return (
		<div className="">
			<svg width={size[0]} height={size[1]} viewBox="0 0 24 24" fill="none">
				<g>
					<path
						d="M7.20997 23.75L19.68 12.75C19.7847 12.6509 19.8681 12.5316 19.9251 12.3992C19.9821 12.2668 20.0115 12.1241 20.0115 11.98C20.0115 11.8359 19.9821 11.6932 19.9251 11.5608C19.8681 11.4284 19.7847 11.3091 19.68 11.21L7.20997 0.249997C7.11092 0.162054 6.99537 0.0946677 6.87006 0.0517509C6.74474 0.00883416 6.61215 -0.00875774 6.47997 -3.15555e-06C6.34674 0.0108389 6.21703 0.0482836 6.09851 0.110117C5.98 0.171951 5.87509 0.256919 5.78997 0.359997L4.23997 2.21C4.14603 2.317 4.07602 2.44283 4.03463 2.57907C3.99325 2.71531 3.98143 2.85882 3.99997 3C4.00956 3.13249 4.04545 3.26175 4.10554 3.38022C4.16563 3.49869 4.24873 3.604 4.34997 3.69L13.8 11.86C13.85 11.9106 13.8781 11.9788 13.8781 12.05C13.8781 12.1212 13.85 12.1894 13.8 12.24L4.34997 20.36C4.24873 20.446 4.16563 20.5513 4.10554 20.6698C4.04545 20.7882 4.00956 20.9175 3.99997 21.05C3.98715 21.1824 4.00089 21.3161 4.04038 21.4432C4.07987 21.5702 4.14433 21.6882 4.22997 21.79L5.78997 23.64C5.87509 23.7431 5.98 23.828 6.09851 23.8899C6.21703 23.9517 6.34674 23.9892 6.47997 24C6.61215 24.0088 6.74474 23.9912 6.87006 23.9482C6.99537 23.9053 7.11092 23.8379 7.20997 23.75Z"
						fill={variant}
					/>
				</g>
				<defs>
					<clipPath id="clip0_2291_8781">
						<rect width={size[0]} height={size[1]} fill="white" />
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};

export default VectorNext;
