import {
	FormEventHandler,
	FunctionComponent,
	useContext,
	useEffect,
	useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import "../player-screen.scss";
import JoinGameHeader from "./JoinGameHeader";
import JoinGameBody from "./JoinGameBody";
import { v4 as uuid } from "uuid";
import { WebSocketContext } from "../../../context/WebSocketContext";
import Message from "../../layout/message/Message";
import { useAppDispatch, useAppSelector } from "../../../services/store/hooks";
import { getGame } from "../../../services/store/features/gameSlice";
import WaitingStart from "./WaitingStart";
import {
	incrementTotalCount,
	resetPlayerSlice,
	savePlayer,
	saveTotalCount,
} from "../../../services/store/features/playerSlice";
import { JoinedPlayer } from "../../../interfaces/player.interface";
import { Alert, Snackbar } from "@mui/material";

interface JoinGamePropsType {}

const JoinGame: FunctionComponent<JoinGamePropsType> = () => {
	let location = useLocation();
	let query = new URLSearchParams(location.search);
	const isRestarted: boolean =
		Boolean(query.get("isRestarted")) && query.get("isRestarted") === "yes";
	const [error, setError] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [pageError, setPageError] = useState<boolean>(false);
	const [pageErrorMessage, setPageErrorMessage] = useState<string>("");
	const [code, setCode] = useState<string>(query.get("code") ?? "");
	const [nickName, setNickName] = useState<string>("");
	const [gettingCode, setgettingCode] = useState<boolean>(code.length === 0);
	const [gettingNickName, setgettingNickName] = useState<boolean>(
		code.length > 0 && !isRestarted
	);
	const [waitingForOthers, setwaitingForOthers] =
		useState<boolean>(isRestarted);
	const [starting, setStarting] = useState<boolean>(false);
	const [ready, setReady] = useState<boolean>(isRestarted);
	const [showSnackbar, setShowSnackbar] = useState<boolean>(false);

	const socket = useContext(WebSocketContext)!;
	const history = useHistory();

	const { config, game: gameState, player } = useAppSelector((state) => state);
	const { game } = gameState;
	const dispatch = useAppDispatch();

	const onNameSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
		event.preventDefault();
		dispatch(savePlayer(null));
		const sessionId = uuid();
		window.localStorage.setItem("userSessionId", sessionId);
		await socket.emit(
			"addPlayerToGame",
			{
				game: game?._id,
				sessionId,
				nickName,
				gameCode: game?.code,
			},
			(response: any) => {
				if (response.message === "nickName Exists") {
					setError(true);
					setErrorMessage(
						`Someone else has already used this nickname.
						 Choose another one.`
					);
					return;
				}
				setReady(true);
				dispatch(savePlayer(response));
				setgettingNickName(false);
				setgettingCode(false);
				setwaitingForOthers(true);
			}
		);
	};
	const onCodeSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
		try {
			event.preventDefault();
			const result = await loadGame();
			const gameCode = code;
			if (result !== "ok") {
				return;
			}

			if (window.location !== window.parent.location) {
				setCode("");
				let linkTimer = setTimeout(() => {
					window.open(`/${config.queryString}&code=${gameCode}`, "_blank");
					clearTimeout(linkTimer);
				});
				return;
			}
			setgettingCode(false);
			setgettingNickName(true);
		} catch (error) {
			setError(true);
			setErrorMessage("No game found. Check your code.");
		}
	};

	async function loadGame(signal?: AbortSignal) {
		try {
			const response = await dispatch(
				getGame({
					token: config.token,
					magazineId: config.magazine.magazineId,
					code: code ?? "",
					signal,
				})
			);
			if (!response.payload) {
				setError(true);
				setErrorMessage("No game found. Check your code.");
				return "noGame";
			}
			if (!isRestarted) {
				dispatch(resetPlayerSlice());
			}
			setError(false);
			setErrorMessage("");

			return "ok";
		} catch (err: any) {
			setError(true);
			setErrorMessage(err.message);
		}
	}

	useEffect(() => {
		if (code.length > 0) {
			const controller = new AbortController();
			loadGame(controller.signal);
			return () => {
				controller.abort();
			};
		}
	}, []);

	useEffect(() => {
		socket.on(
			"onRejectJoin",
			(body: { message: string; sid: string; gameCode: string }) => {
				if (body.gameCode !== code || ready) {
					return;
				}
				if (localStorage.getItem("userSessionId") === body.sid) {
					setPageError(true);
					setPageErrorMessage(body.message);
				}
			}
		);
		return () => {
			socket.off("onRejectJoin");
		};
	}, [socket]);

	useEffect(() => {
		socket.on("connect", () => {
			console.log("[player-socket] - Connected...");
		});
		socket.io.on("disconnect", (reason: string) => {
			console.log("[player-socket] - disconnected...");
			if (reason === "io server disconnect") {
				socket.connect();
			}
		});
		socket.on(
			"addPlayerToGame",
			(body: { players: JoinedPlayer[]; gameCode: string }) => {
				if (body.gameCode !== code) {
					return;
				}
				const isJoinedUser =
					body.players.filter(
						(joinedPlayer) =>
							joinedPlayer.sessionId === player.currentPlayer?.sessionId
					).length > 0;
				if (!isJoinedUser) {
					dispatch(incrementTotalCount());
				}
			}
		);

		return () => {
			console.log("[player-socket] - unregistering the event...");
			socket.off("addPlayerToGame");
			socket.off("connect");
		};
	}, [waitingForOthers]);

	useEffect(() => {
		let startTimer: NodeJS.Timeout;
		socket.on(
			"onStartGame",
			(body: { message: string; totalCount: number; gameCode: string }) => {
				if (body.gameCode !== code) {
					return;
				}

				if (!ready) {
					setShowSnackbar(true);
					setPageError(true);
					setPageErrorMessage("game already started");
					return;
				}
				setStarting(true);
				startTimer = setTimeout(() => {
					history.push(`/player/screen/${code}${config.queryString}`);
					setStarting(false);
					dispatch(saveTotalCount(body.totalCount));
				}, 5000);
			}
		);
		return () => {
			socket.off("onStartGame");
			clearTimeout(startTimer);
		};
	}, [ready]);

	if (pageError) {
		return <Message type="error" text={pageErrorMessage} />;
	}

	return (
		<div className="player-screen">
			{(!starting && (
				<>
					<JoinGameHeader
						gameTitle={game?.quiz.quizName}
						gettingCode={gettingCode}
						gettingNickName={gettingNickName}
						waitingForOthers={waitingForOthers}
						backgroundColor={game?.quiz.quizColor}
					/>
					<JoinGameBody
						gettingCode={gettingCode}
						gettingNickName={gettingNickName}
						waitingForOthers={waitingForOthers}
						code={code}
						nickName={nickName}
						onNameSubmit={onNameSubmit}
						onCodeSubmit={onCodeSubmit}
						setNickName={setNickName}
						setCode={setCode}
						error={error}
						errorMessgae={errorMessage}
						setError={setError}
						setErrorMessage={setErrorMessage}
					/>
				</>
			)) || <WaitingStart gameTitle={game?.quiz.quizName} />}
			<Snackbar
				open={showSnackbar}
				autoHideDuration={3000}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				onClose={() => setShowSnackbar(false)}>
				<Alert
					onClose={() => setShowSnackbar(false)}
					severity="error"
					sx={{ width: "100%" }}>
					<h5 className="upper">game already started!</h5>
				</Alert>
			</Snackbar>
		</div>
	);
};

export default JoinGame;
