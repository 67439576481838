import { Clear } from "@mui/icons-material";
import Button from "@mui/material/Button";
import {
	Dispatch,
	FunctionComponent,
	MouseEvent,
	MouseEventHandler,
	SetStateAction,
} from "react";

interface GameActionsPopupPropsType {
	modalOpen: boolean;
	handleClose: Dispatch<SetStateAction<boolean>>;
	title: string;
	handleEndGame: () => Promise<void>;
	handleRestart: () => void;
}

const GameActionsPopup: FunctionComponent<GameActionsPopupPropsType> = ({
	modalOpen,
	handleClose,
	title,
	handleEndGame,
	handleRestart,
}) => {
	const handleCloseModal = () => handleClose(false);
	const handleIgnoreClick: MouseEventHandler<HTMLDivElement> = (e) =>
		e.stopPropagation();

	const handleEndGameWithClick: MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault();
		handleEndGame();
	};

	return (
		(modalOpen && (
			<div
				className="game-actions-modal-wrapper d-flex justify-content-center align-items-center"
				onClick={handleCloseModal}>
				<div
					className="game-actions-modal"
					onClick={(e) => handleIgnoreClick(e)}>
					<div className="header d-flex justify-content-between align-items-center">
						<span>{title}</span>
						<Clear color="primary" onClick={() => handleClose(false)} />
					</div>
					<div className="content">
						What do you want to do with the current game?
					</div>
					<div className="actions d-flex justify-content-between align-items-center">
						<Button variant="text" className="action" onClick={handleRestart}>
							RESTART
						</Button>
						<Button
							variant="outlined"
							className="action"
							sx={{
								border: "1px solid #6b6b6b",
							}}
							onClick={() => handleClose(false)}>
							CANCEL
						</Button>
						<Button
							variant="contained"
							color="error"
							onClick={handleEndGameWithClick}>
							END GAME
						</Button>
					</div>
				</div>
			</div>
		)) ||
		null
	);
};

export default GameActionsPopup;
