import { createContext, useEffect, useState } from "react";
import Rollbar from "rollbar";

export const RollbarContext = createContext<Rollbar>(new Rollbar());

export function ProvideRollbar({children}: any) {
  const [rollbar, setRollbar] = useState<Rollbar>(new Rollbar())

  useEffect(() => {
    const rollbar = new Rollbar({
      accessToken: '',
      autoInstrument: true,
      captureIp: 'anonymize',
      captureUncaught: true,
      captureUnhandledRejections: true,
      endpoint: `${window.location.origin}/api/rollbar`,
      payload: {},
    });
    setRollbar(rollbar);
  }, []);
  
  return <RollbarContext.Provider value={rollbar}>{children}</RollbarContext.Provider>;
}