import { createContext } from "react";
import { io, Socket } from "socket.io-client";

console.log(window.location.host);
export const socket = io("wss://" + window.location.host, {
	transports: ["websocket", "polling"],
	secure: true,
});

export const WebSocketContext = createContext<Socket>(socket);

export const WebSocketProvider = WebSocketContext.Provider;

// for development mode: use io("ws://localhost:8080". {...})
