import { FunctionComponent, useState } from "react";
import {
	AddNestedQHandlerFunction,
	HandleNestedCorrectAnswerFunction,
	HandleNestedQuestionSelectorsFunction,
	HandleQSSelectorsFunction,
	MoveQuestionHandlerFunction,
	QuestionSettingsHandlerFunction,
	SectionInputHandlerFunction,
} from "../../../interfaces/PropTypes";
import { Section } from "../../../interfaces/quiz.interface";
import NestedQuestionsCard from "./NestedQuestionsCard";
import SectionHeader from "./SectionHeader";
import * as CP from "react-color";
import SectionSettings from "./SectionSettings";

export interface SectionCardPropsType {
	s: Section;
	order: number;
	currentActive: number;
	moveQuestionHandler: MoveQuestionHandlerFunction;
	deleteQSHandler: (index: number, nestedIndex: number) => void;
	questionSettingsHandler: QuestionSettingsHandlerFunction;
	sectionInputHandler: SectionInputHandlerFunction;
	handleQSSelectors: HandleQSSelectorsFunction;
	handleSectionColor: (color: CP.ColorResult, order: number) => void;
	handleNestedQuestionSelectors: HandleNestedQuestionSelectorsFunction;
	handleNestedCorrectAnswer: HandleNestedCorrectAnswerFunction;
	addNestedQHandler: AddNestedQHandlerFunction;
}

const SectionCard: FunctionComponent<SectionCardPropsType> = ({
	s,
	order,
	currentActive,
	moveQuestionHandler,
	deleteQSHandler,
	questionSettingsHandler,
	handleSectionColor,
	handleQSSelectors,
	sectionInputHandler,
	handleNestedQuestionSelectors,
	handleNestedCorrectAnswer,
	addNestedQHandler,
}) => {
	const [toggleSCP, setToggleSCP] = useState<boolean>(false);
	return (
		<div className="question-container">
			<SectionHeader
				s={s}
				moveQuestionHandler={moveQuestionHandler}
				order={order}
				deleteQSHandler={deleteQSHandler}
			/>
			<SectionSettings
				s={s}
				setToggleSCP={setToggleSCP}
				toggleSCP={toggleSCP}
				handleSectionColor={handleSectionColor}
				order={order}
				handleQSSelectors={handleQSSelectors}
				questionSettingsHandler={questionSettingsHandler}
			/>
			<NestedQuestionsCard
				s={s}
				currentActive={currentActive}
				moveQuestionHandler={moveQuestionHandler}
				order={order}
				deleteQSHandler={deleteQSHandler}
				sectionInputHandler={sectionInputHandler}
				handleNestedQuestionSelectors={handleNestedQuestionSelectors}
				handleNestedCorrectAnswer={handleNestedCorrectAnswer}
				addNestedQHandler={addNestedQHandler}
			/>
		</div>
	);
};

export default SectionCard;
