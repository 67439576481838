import { Question, Section } from "./quiz.interface";

export const defaultQuestion: Question = {
	type: "question",
	question: "",
	questionTime: 5,
	answerTime: 10,
	numAnswers: 4,
	mediaUrl: "",
	answers: [
		{ answer: "", correct: false },
		{ answer: "", correct: false },
		{ answer: "", correct: false },
		{ answer: "", correct: false },
	],
};

export const defaultSection: Section = {
	type: "section",
	sectionName: "",
	sectionColor: "#5dc601",
	sectionTime: 5,
	questions: [{ ...defaultQuestion }],
};

export const defaultGenaralSettings = {
	quizName: "",
	quizColor: "",
	showanswers: true,
	keepscore: true,
	showleaderboard: "after-each",
	answer_1_Color: "#fa3845",
	answer_2_Color: "#f57200",
	answer_3_Color: "#2861ff",
	answer_4_Color: "#e606c0",
	questionsCount: 0,
};

export const defaultAnswered = {
	"1": 0,
	"2": 0,
	"3": 0,
	"4": 0,
};

export const defaultColors: string[] = [
	"#fb606a",
	"#fa3845",
	"#e60615",
	"#9f040e",
	"#630309",
	"#ff871f",
	"#f57200",
	"#cc5f00",
	"#a34c00",
	"#522600",
	"#fac402",
	"#ffb300",
	"#e09d00",
	"#b88100",
	"#3d3001",
	"#5dc601",
	"#4ca201",
	"#438d02",
	"#306402",
	"#1d3c01",
	"#05d8f0",
	"#04c6dc",
	"#04a2b4",
	"#037e8c",
	"#024850",
	"#2861ff",
	"#0d20e7",
	"#0b19b5",
	"#09159a",
	"#071074",
	"#b871fe",
	"#8f20fe",
	"#7a01f4",
	"#5801b0",
	"#330165",
	"#fa38da",
	"#e606c0",
	"#b30596",
	"#8b0475",
	"#630353",
	"#999999",
	"#7a7a7a",
	"#666666",
	"#333333",
	"#000000",
];

export const defaultColorsWithGroup = [
	["#fb606a", "#fa3845", "#e60615", "#9f040e", "#630309"],
	["#ff871f", "#f57200", "#cc5f00", "#a34c00", "#522600"],
	["#fac402", "#ffb300", "#e09d00", "#b88100", "#3d3001"],
	["#5dc601", "#4ca201", "#438d02", "#306402", "#1d3c01"],
	["#05d8f0", "#04c6dc", "#04a2b4", "#037e8c", "#024850"],
	["#2861ff", "#0d20e7", "#0b19b5", "#09159a", "#071074"],
	["#b871fe", "#8f20fe", "#7a01f4", "#5801b0", "#330165"],
	["#fa38da", "#e606c0", "#b30596", "#8b0475", "#630353"],
	["#999999", "#7a7a7a", "#666666", "#333333", "#000000"],
];
