import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import { FunctionComponent } from "react";
import defaultSettings from "../../../interfaces/defaultSettings";
import {
	HandleQSSelectorsFunction,
	QuestionSettingsHandlerFunction,
} from "../../../interfaces/PropTypes";
import { Question } from "../../../interfaces/quiz.interface";

interface QuestionSettingsPropsType {
	color: string;
	question: Question;
	questionSettingsHandler: QuestionSettingsHandlerFunction;
	order: number;
	handleQSSelectors: HandleQSSelectorsFunction;
}

const QuestionSettings: FunctionComponent<QuestionSettingsPropsType> = ({
	color,
	question,
	questionSettingsHandler,
	order,
	handleQSSelectors,
}) => {
	return (
		<div
			className="question-settings"
			style={{ backgroundColor: color + "E8" }}>
			<div className="sub-container w-100">
				<div className="name">
					<TextField
						className="custom-text-field w-100"
						required
						label="Question"
						value={question.question}
						onChange={(event) =>
							questionSettingsHandler(event, "question", order, -1)
						}
						inputProps={{
							maxLength: defaultSettings.questionLength,
						}}
					/>
					<FormHelperText className="d-flex justify-content-end px-2">
						{question.question.length || 0}/{defaultSettings.questionLength}
					</FormHelperText>
				</div>
				<div className="time">
					<FormControl fullWidth className="custom-text-field w-100">
						<InputLabel id="demo-simple-select-label-1">
							Time before answer
						</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select-1"
							value={question.questionTime}
							label="Time before answer"
							onChange={(event) =>
								handleQSSelectors(event, "questionTime", order)
							}>
							<MenuItem value={5}>5 Seconds</MenuItem>
							<MenuItem value={10}>10 Seconds</MenuItem>
							<MenuItem value={15}>15 Seconds</MenuItem>
							<MenuItem value={20}>20 Seconds</MenuItem>
							<MenuItem value={30}>30 Seconds</MenuItem>
						</Select>
					</FormControl>
				</div>
			</div>
			<FormControl className="w-100">
				<TextField
					className="custom-text-field"
					label="Media url"
					value={question.mediaUrl}
					onChange={(event) =>
						questionSettingsHandler(event, "mediaUrl", order, -1)
					}
				/>
				<FormHelperText>
					Picture (JPG or PNG), video (Youtube or Vimeo), audio (MP3).
				</FormHelperText>
			</FormControl>
		</div>
	);
};

export default QuestionSettings;
