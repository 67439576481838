import { Slide } from "@mui/material";
import { FunctionComponent } from "react";

interface SliderComponentPropsType {
	children: JSX.Element;
	direction?: "left" | "right" | "up" | "down" | undefined;
	isIn?: boolean | undefined;
}

const SliderComponent: FunctionComponent<SliderComponentPropsType> = ({
	children,
	direction,
	isIn,
}) => {
	return (
		<Slide
			direction={direction ?? "left"}
			in={isIn}
			mountOnEnter
			unmountOnExit
			timeout={400}>
			<div>{children}</div>
		</Slide>
	);
};

export default SliderComponent;
