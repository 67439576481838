import { configureStore } from "@reduxjs/toolkit";
import configReducer from "./features/configSlice";
import gameReducer from "./features/gameSlice";
import playerReducer from "./features/playerSlice";

export const store = configureStore({
	reducer: {
		config: configReducer,
		game: gameReducer,
		player: playerReducer,
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
