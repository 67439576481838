import {
	Checkbox,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import { FunctionComponent } from "react";
import defaultSettings from "../../../interfaces/defaultSettings";
import {
	HandleQSSelectorsFunction,
	QuestionSettingsHandlerFunction,
} from "../../../interfaces/PropTypes";
import { Question } from "../../../interfaces/quiz.interface";

export interface AnswersContainerPropsType {
	color: string;
	question: Question;
	handleQSSelectors: HandleQSSelectorsFunction;
	order: number;
	questionSettingsHandler: QuestionSettingsHandlerFunction;
	handleCorrectAnswer: (questionIndex: number, answerIndex: number) => void;
}

const AnswersContainer: FunctionComponent<AnswersContainerPropsType> = ({
	color,
	question,
	handleQSSelectors,
	order,
	questionSettingsHandler,
	handleCorrectAnswer,
}) => {
	return (
		<div
			className="question-answers container"
			style={{ backgroundColor: color + "CC" }}>
			<div className="d-flex mb-3 align-items-center justify-content-between">
				<div className="answer-settings flex-grow-1">
					<FormControl
						className="custom-text-field"
						sx={{ width: "30%", marginRight: "24px" }}>
						<InputLabel id="demo-simple-select-label-2">
							Number of answers
						</InputLabel>
						<Select
							labelId="demo-simple-select-label-2"
							id="demo-simple-select"
							label="Number of answers"
							value={question.numAnswers}
							onChange={(event) =>
								handleQSSelectors(event, "numAnswers", order)
							}>
							<MenuItem value={2}>2</MenuItem>
							<MenuItem value={4}>4</MenuItem>
						</Select>
					</FormControl>
					<FormControl className="custom-text-field" sx={{ width: "30%" }}>
						<InputLabel id="demo-simple-select-label-2">
							Time to answer
						</InputLabel>
						<Select
							labelId="demo-simple-select-label-2"
							id="demo-simple-select"
							label="Time to answer"
							value={question.answerTime}
							onChange={(event) =>
								handleQSSelectors(event, "answerTime", order)
							}>
							<MenuItem value={5}>5 Seconds</MenuItem>
							<MenuItem value={10}>10 Seconds</MenuItem>
							<MenuItem value={15}>15 Seconds</MenuItem>
							<MenuItem value={20}>20 Seconds</MenuItem>
							<MenuItem value={30}>30 Seconds</MenuItem>
						</Select>
					</FormControl>
				</div>
				<div className=" correct-answer flex-shrink-0 text-center">
					CORRECT <br /> ANSWER
				</div>
			</div>
			{question.answers &&
				question.answers.map((e1, i1) => (
					<div
						className="d-flex mb-3 align-items-center justify-content-between"
						key={i1}>
						<div className="flex-grow-1">
							<TextField
								className="custom-text-field w-100"
								required
								label={`Answer ${i1 + 1}`}
								value={e1.answer}
								sx={{
									input: {
										color: "#ffffff",
									},
								}}
								onChange={(event) =>
									questionSettingsHandler(event, "answer", order, i1)
								}
								focused
								inputProps={{
									maxLength: defaultSettings.answerLength,
								}}
							/>
							<FormHelperText className="d-flex justify-content-end px-2">
								{e1.answer.length || 0}/{defaultSettings.answerLength}
							</FormHelperText>
						</div>
						<div className="flex-shrink-0 text-center checkbox mb-4 d-flex justify-content-end">
							<Checkbox
								checked={e1.correct}
								onChange={() => handleCorrectAnswer(order, i1)}
								sx={{
									color: "#ffffff",
									"&.Mui-checked": {
										color: "#ffffff",
									},
								}}
							/>
						</div>
					</div>
				))}
		</div>
	);
};

export default AnswersContainer;
