import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: "white",
	background: "#A90073",
	"&:hover": {
		backgroundColor: "#A90073",
	},
	fontWeight: 700,
	fontSize: 20,
	borderRadius: "56px",
	padding: "16px 47px",
	margin: "24px auto",
	display: "block",
	boxShadow:
		"0px 6px 12px rgba(169, 0, 115, 0.3), inset 0px -2px 0px rgba(0, 0, 0, 0.2)",
}));

const PlayerStartButton = ({ buttonText }: { buttonText: string }) => (
	<CustomButton type="submit" sx={{ color: "white" }}>
		{buttonText}
	</CustomButton>
);

export default PlayerStartButton;
