import { Button } from "@mui/material";
import { FunctionComponent } from "react";
import { AddNestedQHandlerFunction } from "../../../interfaces/PropTypes";

interface AddNestedQuestionPropsType {
	key?: any;
	textColor: string;
	sectionIndex: number;
	quesitonIndex: number;
	addNestedQHandler: AddNestedQHandlerFunction;
}

const AddNestedQuestion: FunctionComponent<AddNestedQuestionPropsType> = ({
	textColor,
	sectionIndex,
	quesitonIndex,
	addNestedQHandler,
}) => {
	return (
		<div className="mmd">
			<Button
				variant="contained"
				sx={{
					color: textColor,
					backgroundColor: "#ffffff",
					"&:hover": {
						backgroundColor: "#eeeeee",
					},
				}}
				onClick={(event) =>
					addNestedQHandler(event, sectionIndex, quesitonIndex)
				}>
				ADD QUESTION
			</Button>
		</div>
	);
};

export default AddNestedQuestion;
