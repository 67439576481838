import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface ModalPropsType {
	open: boolean;
	handleSubmit: () => void;
	handleClose: () => void;
	text: string;
	action: string;
}

const CustomModal: React.FunctionComponent<ModalPropsType> = ({
	open,
	handleClose,
	text,
	action,
	handleSubmit,
}) => {
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				sx={{
					div: {
						padding: "20px 40px",
					},
				}}
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title" className="h1">
					{action}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{text}
					</DialogContentText>
				</DialogContent>
				<DialogActions className="mb-3">
					<Button variant="outlined" color="error" onClick={handleClose}>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={handleSubmit}
						autoFocus>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default CustomModal;
