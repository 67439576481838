import axios from "axios";
import { GameInterface } from "@project/interfaces/game-interface.interface";
import { Score } from "../interfaces/player.interface";

function paramValidator(param: string, errPartial: string, paramName: string) {
	if (!param || param.length === 0) {
		throw Error(`${errPartial} No ${paramName}`);
	}
}

export const createGame = async (
	token?: string,
	magazineId?: string,
	id?: string
): Promise<string> => {
	paramValidator(token || "", ``, "token");
	paramValidator(magazineId || "", "", "magazine id");
	paramValidator(id || "", ``, "id");
	const url = `/api/game/${id}`;
	const headers = {
		"Content-Type": "application/json",
		"X-Magazine-Id": magazineId,
		Authorization: token,
	};
	try {
		const { data } = await axios.post(url, {}, { headers });
		return data;
	} catch (error) {
		throw Error(`create game with quiz id: ${id} failed, error: ${error}`);
	}
};

export const getGame = async (
	token: string,
	magazineId: string,
	id: string,
	signal?: AbortSignal
): Promise<GameInterface> => {
	paramValidator(token || "", ``, "token");
	paramValidator(magazineId || "", ``, "magazine id");
	paramValidator(id || "", ``, "id");
	const url = `/api/game/${id}`;
	const headers = {
		"Content-Type": "application/json",
		"X-Magazine-Id": magazineId,
		Authorization: token,
	};
	try {
		const { data } = await axios.get(url, { headers, signal });
		return data;
	} catch (error) {
		throw Error(`get game with quiz with id: ${id} failed, error: ${error}`);
	}
};

export const resetGamePlayers = async (
	token: string,
	magazineId: string,
	id: string,
	signal?: AbortSignal
): Promise<GameInterface> => {
	paramValidator(token || "", ``, "token");
	paramValidator(magazineId || "", ``, "magazine id");
	paramValidator(id || "", ``, "id");
	const url = `/api/game/${id}`;
	const headers = {
		"Content-Type": "application/json",
		"X-Magazine-Id": magazineId,
		Authorization: token,
	};
	try {
		const { data } = await axios.put(url, {}, { headers, signal });
		return data;
	} catch (error) {
		throw Error(`reset game players failed, error: ${error}`);
	}
};

export const endGame = async (
	token: string,
	magazineId: string,
	id: string,
	signal?: AbortSignal
): Promise<any> => {
	paramValidator(token || "", ``, "token");
	paramValidator(magazineId || "", ``, "magazine id");
	paramValidator(id || "", ``, "id");
	const url = `/api/game/end/${id}`;
	const headers = {
		"Content-Type": "application/json",
		"X-Magazine-Id": magazineId,
		Authorization: token,
	};
	try {
		const { data } = await axios.delete(url, { headers, signal: signal });
		return data;
	} catch (error) {
		throw Error(`end game failed, error: ${error}`);
	}
};

export const endExpiredGames = async (
	token: string,
	magazineId: string,
	id: string,
	signal?: AbortSignal
): Promise<any> => {
	paramValidator(token || "", ``, "token");
	paramValidator(magazineId || "", ``, "magazine id");
	paramValidator(id || "", ``, "id");
	const url = `/api/game/end-game`;
	const headers = {
		"Content-Type": "application/json",
		"X-Magazine-Id": magazineId,
		Authorization: token,
	};
	try {
		const { data } = await axios.delete(url, { headers, signal: signal });
		return data;
	} catch (error) {
		throw Error(`end expired games failed, error: ${error}`);
	}
};

export const saveScore = async (
	_id: string,
	questionIndex: number,
	score: number
): Promise<void> => {
	const url = `/api/player/save-score`;
	const headers = {
		"Content-Type": "application/json",
	};
	try {
		await axios.post(url, { _id, questionIndex, score }, { headers });
	} catch (error) {
		throw Error(`save score failed, error: ${error}`);
	}
};

export const getScore = async (
	gameId: string,
	signal?: AbortSignal
): Promise<Score[]> => {
	const url = `/api/player/scores/${gameId}`;
	const headers = {
		"Content-Type": "application/json",
	};
	try {
		const { data } = await axios.get(url, { headers, signal });
		return data;
	} catch (error) {
		throw Error(`get scores failed, error: ${error}`);
	}
};
