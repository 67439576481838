import { Link } from "react-router-dom";
import { useAppSelector } from "../../services/store/hooks";
export interface DashboardHeaderPropsType {}

function DashboardHeader() {
	const { queryString: urlQueryString } = useAppSelector(
		(state) => state.config
	);
	return (
		<>
			<div className="d-flex justify-content-between align-items-center">
				<div className="page-title">All Games</div>
				<Link to={`/create-quiz${urlQueryString}`}>
					<button className="btn btn-create">CREATE NEW GAME</button>
				</Link>
			</div>
			<div className="divider my-3"></div>
		</>
	);
}

export default DashboardHeader;
