import "./Message.scss";

function Message(props: { type: "error" | "warning"; text: string }) {
	return (
		<div>
			<i className={`icon-message ${props.type}`}></i>
			<div className="message">{props.text}</div>
		</div>
	);
}
export default Message;
