import { FunctionComponent } from "react";

interface FullScreenPropsType {
	variant: string;
	size: number;
}

const FullScreen: FunctionComponent<FullScreenPropsType> = ({
	variant,
	size,
}) => {
	return (
		<div className="">
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<g>
					<path
						d="M23.7499 2.45C23.7473 1.86733 23.5147 1.30928 23.1027 0.897272C22.6907 0.48526 22.1326 0.252631 21.5499 0.25H15.8199C15.4884 0.25 15.1705 0.381696 14.9361 0.616117C14.7016 0.850537 14.5699 1.16848 14.5699 1.5C14.5699 1.83152 14.7016 2.14946 14.9361 2.38388C15.1705 2.6183 15.4884 2.75 15.8199 2.75H20.9999C21.0663 2.75 21.1298 2.77634 21.1767 2.82322C21.2236 2.87011 21.2499 2.9337 21.2499 3V6.27C21.2499 6.60152 21.3816 6.91946 21.6161 7.15388C21.8505 7.3883 22.1684 7.52 22.4999 7.52C22.8315 7.52 23.1494 7.3883 23.3838 7.15388C23.6183 6.91946 23.7499 6.60152 23.7499 6.27V2.45Z"
						fill={variant}
					/>
					<path
						d="M0.25 6.27C0.25 6.60152 0.381696 6.91946 0.616117 7.15388C0.850537 7.3883 1.16848 7.52 1.5 7.52C1.83152 7.52 2.14946 7.3883 2.38388 7.15388C2.6183 6.91946 2.75 6.60152 2.75 6.27V3C2.75 2.9337 2.77634 2.87011 2.82322 2.82322C2.87011 2.77634 2.9337 2.75 3 2.75H8.18C8.51152 2.75 8.82946 2.6183 9.06388 2.38388C9.2983 2.14946 9.43 1.83152 9.43 1.5C9.43 1.16848 9.2983 0.850537 9.06388 0.616117C8.82946 0.381696 8.51152 0.25 8.18 0.25H2.45C1.86733 0.252631 1.30928 0.48526 0.897272 0.897272C0.48526 1.30928 0.252631 1.86733 0.25 2.45V6.27Z"
						fill={variant}
					/>
					<path
						d="M23.7499 21.54V17.73C23.7499 17.3985 23.6183 17.0805 23.3838 16.8461C23.1494 16.6117 22.8315 16.48 22.4999 16.48C22.1684 16.48 21.8505 16.6117 21.6161 16.8461C21.3816 17.0805 21.2499 17.3985 21.2499 17.73V21C21.2499 21.0663 21.2236 21.1299 21.1767 21.1768C21.1298 21.2237 21.0663 21.25 20.9999 21.25H15.8199C15.4884 21.25 15.1705 21.3817 14.9361 21.6161C14.7016 21.8505 14.5699 22.1685 14.5699 22.5C14.5699 22.8315 14.7016 23.1495 14.9361 23.3839C15.1705 23.6183 15.4884 23.75 15.8199 23.75H21.5499C22.1343 23.7474 22.6939 23.5133 23.1062 23.0992C23.5185 22.685 23.75 22.1244 23.7499 21.54Z"
						fill={variant}
					/>
					<path
						d="M0.25 21.54C0.249994 22.1244 0.481459 22.685 0.893754 23.0992C1.30605 23.5133 1.86561 23.7474 2.45 23.75H8.18C8.51152 23.75 8.82946 23.6183 9.06388 23.3839C9.2983 23.1495 9.43 22.8315 9.43 22.5C9.43 22.1685 9.2983 21.8505 9.06388 21.6161C8.82946 21.3817 8.51152 21.25 8.18 21.25H3C2.9337 21.25 2.87011 21.2237 2.82322 21.1768C2.77634 21.1299 2.75 21.0663 2.75 21V17.73C2.75 17.3985 2.6183 17.0805 2.38388 16.8461C2.14946 16.6117 1.83152 16.48 1.5 16.48C1.16848 16.48 0.850537 16.6117 0.616117 16.8461C0.381696 17.0805 0.25 17.3985 0.25 17.73V21.54Z"
						fill={variant}
					/>
					<path
						d="M16.77 8.14H7.22002C6.14306 8.14 5.27002 9.01304 5.27002 10.09V13.92C5.27002 14.997 6.14306 15.87 7.22002 15.87H16.77C17.847 15.87 18.72 14.997 18.72 13.92V10.09C18.72 9.01304 17.847 8.14 16.77 8.14Z"
						fill={variant}
					/>
				</g>
				<defs>
					<clipPath id="clip0_2280_7820">
						<rect width={size} height={size} fill="white" />
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};

export default FullScreen;
