import { Questions, Section } from "../interfaces/quiz.interface";

export const countQuestions = (questions?: Questions): number => {
	if (!questions) return 0;
	let result = 0;
	questions?.forEach((q) => {
		if (q.type === "question") {
			result++;
		}
		if (q.type === "section") {
			result += (q as Section).questions.length;
		}
	});
	return result;
};

export const currentActive = (
	parentIndex: number,
	childIndex: number,
	questions?: Questions
) => {
	let result = 0;
	if (questions) {
		for (let i = 0; i <= parentIndex; i++) {
			const current = questions[i];
			if (current.type === "question") {
				result++;
			} else {
				if (i === parentIndex) {
					result += childIndex + 1;
				} else {
					result += (current as Section).questions.length;
				}
			}
		}
	}
	return result === 0 ? 1 : result;
};
