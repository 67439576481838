import * as React from "react";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import { grey } from "@mui/material/colors";
import { Tooltip } from "@mui/material";

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: theme.palette.getContrastText(grey[50]),
	backgroundColor: grey[50],
	"&:hover": {
		backgroundColor: grey[200],
	},
	fontWeight: 700,
	fontSize: 20,
	borderRadius: "56px",
	padding: "16px 24px",
	marginTop: "80px",
	boxShadow:
		"0px 6px 12px rgba(0, 0, 0, 0.3), inset 0px -2px 0px rgba(0, 0, 0, 0.2)",
}));

interface propsType {
	handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
	disabled: boolean;
}

const StartGameButton = ({ handleClick, disabled }: propsType) => (
	<CustomButton
		sx={{ color: "#0071B3" }}
		onClick={(event) => handleClick(event)}
		disabled={disabled}>
		START GAME
	</CustomButton>
);

export default StartGameButton;
