import axios from "axios";
import { MagazineInterface } from "../interfaces/magazine.interface";
import { Quiz } from "../interfaces/quiz.interface";
import { StatsInterface } from "../interfaces/stats.interface";

function handleErrors(res: Response) {
	if (!res.ok) {
		throw Error(res.status.toString());
	}
	return res;
}

function paramValidator(param: string, errPartial: string, paramName: string) {
	if (!param || param.length === 0) {
		throw Error(`${errPartial} No ${paramName}`);
	}
}

export const getMagazine = (
	token: string,
	magazineId: string,
	signal: AbortSignal
): Promise<MagazineInterface> => {
	const url = `/api/config/${magazineId}`;
	const headers = {
		"Content-Type": "application/json",
		"X-Magazine-Id": magazineId,
		Authorization: token,
	};
	return fetch(url, { headers, signal })
		.then(handleErrors)
		.then((res) => res.json());
};

// quiz services
export const getQuizes = async (
	token: string,
	magazineId: string
): Promise<Quiz[]> => {
	const url = `/api/quizes/get-all/${magazineId}`;
	const headers = {
		"Content-Type": "application/json",
		"X-Magazine-Id": magazineId,
		Authorization: token,
	};

	paramValidator(token, "get Quizes failed error:", "token");
	paramValidator(magazineId, "get Quizes failed error:", "magazine id");

	const { data } = await axios.get(url, { headers });
	return data;
};

export const deleteQuizById = async (
	token?: string,
	magazineId?: string,
	id?: string
): Promise<any> => {
	if (!token || !magazineId) {
		throw Error(
			`delete quiz with id: ${id} failed, error: "No token or invalid magazine id"`
		);
	}
	const url = `/api/quizes/delete/${id}`;
	const headers = {
		"Content-Type": "application/json",
		"X-Magazine-Id": magazineId,
		Authorization: token,
	};
	paramValidator(token, "delete quiz by id failed error:", "token");
	paramValidator(magazineId, "delete quiz by id failed error:", "magazine id");
	paramValidator(id || "", "delete quiz by id failed error:", "quiz id");
	try {
		const { data } = await axios.delete(url, { headers });
		return data;
	} catch (error) {
		throw Error(`delete quiz with id: ${id} failed, error: ${error}`);
	}
};

export const getQuizById = async (
	token: string,
	magazineId: string,
	id: string,
	signal: AbortSignal
): Promise<Quiz> => {
	const url = `/api/quizes/get/${id}`;
	const headers = {
		"Content-Type": "application/json",
		"X-Magazine-Id": magazineId,
		Authorization: token,
	};

	paramValidator(token, "get quiz by id failed error:", "token");
	paramValidator(magazineId, "get quiz by id failed error:", "magazine id");
	paramValidator(id || "", "get quiz by id failed error:", "quiz id");
	try {
		const { data } = await axios.get(url, { headers, signal });
		return data;
	} catch (error) {
		throw Error(`get quiz with id: ${id} failed, error: ${error}`);
	}
};

export const updateQuizById = async (
	token: string,
	magazineId: string,
	id: string,
	quiz: Quiz
): Promise<Quiz> => {
	const url = `/api/quizes/update/${id}`;
	const headers = {
		"Content-Type": "application/json",
		"X-Magazine-Id": magazineId,
		Authorization: token,
	};

	paramValidator(token, "update quiz by id failed error:", "token");
	paramValidator(magazineId, "update quiz by id failed error:", "magazine id");
	paramValidator(id || "", "update quiz by id failed error:", "quiz id");
	try {
		const { data } = await axios.post(url, quiz, { headers });
		return data;
	} catch (error) {
		throw Error(`update quiz with id: ${id} failed, error: ${error}`);
	}
};

export const createQuiz = async (
	token: string,
	magazineId: string,
	quiz: Quiz
): Promise<Quiz> => {
	const url = `/api/quizes/create`;
	const headers = {
		"Content-Type": "application/json",
		"X-Magazine-Id": magazineId,
		Authorization: token,
	};

	paramValidator(token, "create quiz failed error:", "token");
	paramValidator(magazineId, "create quiz failed error:", "magazine id");
	try {
		const { data } = await axios.post(url, quiz, { headers });
		return data;
	} catch (error) {
		throw Error("create quiz failed");
	}
};

export const updateStats = async (
	token: string,
	magazineId: string,
	stats: StatsInterface,
	signal: AbortSignal
): Promise<any> => {
	const url = `/api/statistics`;
	const headers = {
		"Content-Type": "application/json",
		"X-Magazine-Id": magazineId,
		Authorization: token,
	};
	try {
		const { data } = await axios.post(url, stats, { headers, signal });
		return data;
	} catch (error) {
		throw Error(`update stats failed, error: ${error}`);
	}
};
