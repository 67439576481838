import { FunctionComponent, useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../services/store/hooks";
import ordinal from "ordinal";
import "../player-screen.scss";
import { Question, Section } from "../../../interfaces/quiz.interface";
import {
	getGame,
	resetGameSlice,
} from "../../../services/store/features/gameSlice";
import { useHistory, useParams } from "react-router-dom";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import Message from "../../layout/message/Message";
import PlayGameHeader from "./PlayGameHeader";
import QuestionContainer from "./QuestionContainer";
import AnswersContainer from "./AnswersContainer";
import * as GameApi from "../../../services/GameApi";
import { WebSocketContext } from "../../../context/WebSocketContext";
import {
	incrementAnsweredCount,
	resetAnsweredCount,
	resetPlayerSlice,
	saveTotalCount,
} from "../../../services/store/features/playerSlice";
import { Score } from "../../../interfaces/player.interface";
import LeaderboardPlayer from "./LeaderboardPlayer";
import { deletePlayer, resetPlayerScore } from "../../../services/PlayerApi";

interface PlayerScreenPropsType {}

const PlayerScreen: FunctionComponent<PlayerScreenPropsType> = () => {
	const { game, player, config } = useAppSelector((state) => state);
	const dispatch = useAppDispatch();
	const questions = game.game?.quiz.questions;
	const { code } = useParams<{ code: string }>();
	const [showingSection, setShowingSection] = useState<boolean>(false);
	const [showingQuestion, setShowingQuestion] = useState<boolean>(false);
	const [showingAnswer, setShowingAnswer] = useState<boolean>(false);
	const [showingResult, setShowingResult] = useState<boolean>(false);
	const [childIndex, setChildIndex] = useState<number>(-1);
	const [parentIndex, setParentIndex] = useState<number>(0);
	const [error, setError] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [waitingNextQuestion, setWaitingNextQuestion] =
		useState<boolean>(false);
	const [aStatus, setAstatus] = useState<"correct" | "wrong" | "noAnswer">(
		"noAnswer"
	);
	const [score, setScore] = useState<number>(0);
	const [t0, setT0] = useState<number>(0);
	const [showLeaderboard, setShowLeaderboard] = useState<boolean>(false);
	const [total, setTotal] = useState<number>(0);
	const [order, setOrder] = useState<number>(1);
	const [showingRank, setShowingRank] = useState<boolean>(false);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [restart, setRestart] = useState<boolean>(false);
	const flexGrow = () =>
		(!game.game?.quiz.showanswers && showingResult && "flex-grow-0") ||
		"flex-grow-1";
	const history = useHistory();

	const socket = useContext(WebSocketContext)!;

	/**
	 *
	 * @param t1 answer submit time
	 * @param t0 start answer time
	 * @returns void
	 */
	const handleScore = async (
		t1: number,
		scored: boolean,
		questionIndex: number,
		index: number
	) => {
		try {
			const currentStatus = scored ? "correct" : "wrong";
			setAstatus(currentStatus);
			socket.emit("setAnswerIndex", { index, gameCode: code });
			// notify all that some one answered
			if (scored) {
				const currentScore: number =
					100 +
					Math.ceil(
						(5 *
							(currentQuestion(parentIndex, childIndex).answerTime * 1000 -
								(t1 - t0))) /
							1000
					);

				await GameApi.saveScore(
					player.currentPlayer?._id ?? "",
					questionIndex,
					currentScore
				);
				setScore(currentScore);
			} else {
				setScore(0);
			}

			setError(false);
			setErrorMessage("");
		} catch (error) {
			setError(true);
			setErrorMessage("calculate score failed");
		}
	};

	// survive from refresh
	async function loadGame(signal?: AbortSignal) {
		try {
			await dispatch(
				getGame({
					token: config.token,
					magazineId: config.magazine.magazineId,
					code: code ?? "",
					signal,
				})
			);
		} catch (error) {
			setError(true);
			setErrorMessage(`load game failed, error: ${error}`);
		}
	}

	// utils

	// time calculator
	const generateTimesObject = (parentIndex: number, childIndex: number) => {
		let sectionTime: number = 0;
		let questionTime: number = 0;
		let answerTime: number = 0;
		if (questions && questions.length) {
			if (questions[parentIndex].type === "question") {
				const currentItem = questions[parentIndex] as Question;
				sectionTime = 0;
				questionTime = currentItem.questionTime;
				answerTime = currentItem.answerTime;
			} else {
				const currentItem = [...questions][parentIndex] as Section;
				if (childIndex <= 0) {
					sectionTime = currentItem.sectionTime;
					questionTime = currentItem.questions[0].questionTime;
					answerTime = currentItem.questions[0].answerTime;
				} else {
					sectionTime = 0;
					questionTime = currentItem.questions[childIndex].questionTime;
					answerTime = currentItem.questions[childIndex].answerTime;
				}
			}
		}
		return {
			sectionTime: sectionTime,
			questionTime: questionTime + 1,
			answerTime: answerTime + 1,
			total: sectionTime + questionTime + answerTime,
		};
	};

	const chooseColor = (parentIndex: number) => {
		if (questions?.[parentIndex]?.type === "question") {
			return game.game?.quiz.quizColor;
		} else {
			return (questions?.[parentIndex] as Section).sectionColor;
		}
	};

	const currentQuestion = (
		parentIndex: number,
		childIndex: number
	): Question => {
		if (questions?.[parentIndex].type === "section") {
			if (childIndex < 0) {
				childIndex = 0;
			}
			return [...(questions?.[parentIndex] as Section).questions][childIndex];
		}
		return questions?.[parentIndex] as Question;
	};

	const handleNextGame = (body: {
		newParentIndex: number;
		newChildIndex: number;
	}) => {
		dispatch(resetAnsweredCount());
		setShowingSection(false);
		setShowingQuestion(false);
		setShowingAnswer(false);
		setShowingResult(false);
		setAstatus("noAnswer");
		setChildIndex(body.newChildIndex);
		setParentIndex(body.newParentIndex);
		setShowingRank(false);
	};

	const answeredText = () => {
		return `${player.answeredCount}/${player.totalCount}`;
	};

	const handleNewGame = (restartQuery: string) => {
		history.push(`/${config.queryString}${restartQuery}`);
	};

	useEffect(() => {
		const times = generateTimesObject(parentIndex, childIndex);
		let sectionTimer: NodeJS.Timeout,
			answerTimer: NodeJS.Timeout,
			resultTimer: NodeJS.Timeout;
		if (times.questionTime > 1) {
			if (childIndex <= 0) {
				setShowingSection(true);
				sectionTimer = setTimeout(() => {
					setShowingSection(false);
					setShowingQuestion(true);
				}, times.sectionTime * 1000);
			} else {
				setShowingQuestion(true);
			}
			answerTimer = setTimeout(() => {
				setShowingQuestion(false);
				setShowingAnswer(true);
				setT0(Date.now());
			}, (times.sectionTime + times.questionTime) * 1000);
			resultTimer = setTimeout(() => {
				setShowingAnswer(false);
				setShowingQuestion(false);
				setShowingResult(true);
			}, (times.sectionTime + times.questionTime + times.answerTime) * 1000);
		}

		socket.on("disconnect", (reason: string) => {
			if (reason === "io server disconnect") {
				socket.connect();
			}
			setWaitingNextQuestion(true);
			// TODO: set timeout for long wait
		});

		return () => {
			if (sectionTimer) {
				clearTimeout(sectionTimer);
			}
			socket.off("disconnect");
			clearTimeout(answerTimer);
			clearTimeout(resultTimer);
		};
	}, [parentIndex, childIndex]);

	useEffect(() => {
		const controller = new AbortController();
		loadGame(controller.signal);
		return () => {
			controller.abort();
		};
	}, []);

	useEffect(() => {
		let innerTimer: NodeJS.Timeout;
		socket.on(
			"showLastLeaderboard",
			(body: { newScores: Score[]; gameCode: string }) => {
				if (body.gameCode !== code) {
					return;
				}
				if (waitingNextQuestion) {
					setWaitingNextQuestion(false);
				}
				const order: number = body.newScores.findIndex(
					(s) => s.sessionId === player.currentPlayer?.sessionId
				);
				setShowLeaderboard(true);
				setTotal((p) => body.newScores?.[order].score || p);
				setOrder((p) => order + 1);
			}
		);
		return () => {
			socket.off("showLastLeaderboard");
			clearTimeout(innerTimer);
		};
	}, [waitingNextQuestion]);

	useEffect(() => {
		const controller: AbortController = new AbortController();
		let endGameTimer: NodeJS.Timeout, restartGameTimer: NodeJS.Timeout;
		socket.on(
			"endGame",
			async ({ message, gameCode }: { message: string; gameCode: string }) => {
				if (gameCode !== code) {
					return;
				}
				if (waitingNextQuestion) {
					setWaitingNextQuestion(false);
				}
				await deletePlayer(player.currentPlayer?._id || "", controller.signal);
				dispatch(resetGameSlice());
				dispatch(resetPlayerSlice());
				setShowSnackbar(true);

				if (!showLeaderboard) {
					endGameTimer = setTimeout(() => {
						setShowSnackbar(false);
						handleNewGame("");
					}, 3000);
				}
			}
		);
		socket.on(
			"restart",
			async ({ message, gameCode }: { message: string; gameCode: string }) => {
				if (gameCode !== code) {
					return;
				}
				if (waitingNextQuestion) {
					setWaitingNextQuestion(false);
				}
				dispatch(resetAnsweredCount());
				setRestart(true);
				await resetPlayerScore(
					player.currentPlayer?._id || "",
					controller.signal
				);
				setShowSnackbar(true);

				restartGameTimer = setTimeout(() => {
					setShowSnackbar(false);
					handleNewGame(`&isRestarted=yes&code=${code}`);
				}, 3000);
			}
		);

		return () => {
			socket.off("endGame");
			socket.off("restart");
			clearTimeout(endGameTimer);
			clearTimeout(restartGameTimer);
			controller.abort();
		};
	}, [showLeaderboard, waitingNextQuestion]);

	useEffect(() => {
		socket.on("connect", () => {
			console.log("[player game screen] -- Connected...");
		});

		socket.on(
			"questionSkipNext",
			async (body: {
				newParentIndex: number;
				newChildIndex: number;
				gameCode: string;
			}) => {
				if (body.gameCode !== code) {
					return;
				}
				if (waitingNextQuestion) {
					setWaitingNextQuestion(false);
				}
				handleNextGame({
					newParentIndex: body.newParentIndex,
					newChildIndex: body.newChildIndex,
				});
			}
		);

		socket.on("sendRank", (body: { newScores: Score[]; gameCode: string }) => {
			if (body.gameCode !== code) {
				return;
			}
			const order: number = body.newScores.findIndex(
				(s) => s.sessionId === player.currentPlayer?.sessionId
			);
			setOrder((p) => order + 1);
			setTotal(body.newScores[order].score);
			setShowingRank(true);
		});
		socket.on("setAnswerIndex", (data: { index: number; gameCode: string }) => {
			if (data.gameCode !== code) return;
			dispatch(incrementAnsweredCount());
		});
		return () => {
			socket.off("connect");
			socket.off("sendRank");
			socket.off("setAnswerIndex");
			socket.off("questionSkipNext");
		};
	}, [childIndex, parentIndex, waitingNextQuestion]);

	useEffect(() => {
		if (showingResult) {
			setTotal((p) => p + score);
			return;
		}
		setScore(0);
	}, [showingResult]);

	if (error) {
		return <Message type="error" text={errorMessage} />;
	}

	if (!questions) {
		return (
			<div className="vh-100 w-100 d-flex justify-content-center align-items-center flex-column">
				<CircularProgress size={100} />
				<div className="mt-3 text-info h4">loading the game...</div>
			</div>
		);
	}

	if (waitingNextQuestion) {
		return (
			<div className="vh-100 w-100 d-flex justify-content-center align-items-center flex-column">
				<CircularProgress size={100} />
				<div className="mt-3 text-info h4">waiting for next question...</div>
			</div>
		);
	}

	return (
		<div className="player-game-screen d-flex flex-column">
			<PlayGameHeader
				color={chooseColor(parentIndex) || "#0071B3"}
				showingQuestion={showingQuestion}
				showingAnswer={showingAnswer}
				showingSection={showingSection}
				parentIndex={parentIndex}
				childIndex={childIndex}
				showingResult={showingResult}
				aStatus={aStatus}
				score={score}
				showLeaderboard={showLeaderboard}
				currentQuestion={currentQuestion(parentIndex, childIndex)}
			/>
			{(showLeaderboard && (
				<LeaderboardPlayer
					total={total}
					order={order}
					handleNewGame={handleNewGame}
				/>
			)) ||
				((showingAnswer || showingResult) && (
					<AnswersContainer
						currentQuestion={currentQuestion(parentIndex, childIndex)}
						parentIndex={parentIndex}
						childIndex={childIndex}
						showingResult={showingResult}
						handleScore={handleScore}
						flexGrow={flexGrow()}
					/>
				)) || (
					<QuestionContainer
						currentQuestion={currentQuestion(parentIndex, childIndex)}
						parentIndex={parentIndex}
						childIndex={childIndex}
						showingSection={showingSection}
						flexGrow={flexGrow()}
					/>
				)}
			{!showLeaderboard && (
				<div className="footer d-flex justify-content-between align-items-center flex-grow-0">
					<div className="text">
						{showingRank && <span>{`${ordinal(order)} PLACE - `}</span>}
						<span> {total} Points</span>
					</div>
					<div className="text">ANSWERED: {answeredText()}</div>
				</div>
			)}
			<Snackbar
				open={showSnackbar}
				autoHideDuration={5000}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				onClose={() => setShowSnackbar(false)}>
				<Alert
					onClose={() => setShowSnackbar(false)}
					severity="error"
					sx={{ width: "100%" }}>
					<h5>
						{(restart && "Quiz Master restarted the game!") ||
							"Quiz Master Ended The Game!"}{" "}
						{!showLeaderboard && "We redirecting you to the home page!"}
					</h5>
				</Alert>
			</Snackbar>
		</div>
	);
};

export default PlayerScreen;
