import {
	FunctionComponent,
	MouseEvent,
	useContext,
	useEffect,
	useState,
} from "react";
import Message from "../../layout/message/Message";
import "../MainScreen.scss";
import { WebSocketContext } from "../../../context/WebSocketContext";
import { useAppDispatch, useAppSelector } from "../../../services/store/hooks";
import PbWithTime from "../../partials/progressbar/PbWithTime";
import MasterHeader from "./MasterHeader";
import {
	Answered,
	Question,
	Section,
} from "../../../interfaces/quiz.interface";
import { FullScreenHandle } from "react-full-screen";
import VectorNext from "../../partials/vectors/vector-next";
import VectorRight from "../../partials/vectors/vector-right";
import QuestionContainer from "./QuestionContainer";
import ResultContainer from "./ResultContainer";
import {
	incrementAnsweredCount,
	resetAnsweredCount,
	resetPlayerSlice,
} from "../../../services/store/features/playerSlice";
import { defaultAnswered } from "../../../interfaces/default-values";
import Leaderboard from "./Leaderboard";
import { countQuestions, currentActive } from "../../utils";
import VectorOff from "../../partials/vectors/vector-off";
import GameActionsPopup from "./GameActionsPopup";
import { useHistory } from "react-router-dom";
import { resetGameSlice } from "../../../services/store/features/gameSlice";
import { endGame } from "../../../services/GameApi";

interface MainScreenCompoenentPropsType {
	handle: FullScreenHandle;
}

const MasterScreenCompoenent: FunctionComponent<
	MainScreenCompoenentPropsType
> = ({ handle }) => {
	const [error, setError] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [showingSection, setShowingSection] = useState<boolean>(false);
	const [showingQuestions, setShowingQuestions] = useState<boolean>(false);
	const [showingAnswers, setShowingAnswers] = useState<boolean>(false);
	const [showingResult, setShowingResult] = useState<boolean>(false);
	const [answered, setAnswered] = useState<Answered>(defaultAnswered);
	const [parentIndex, setParentIndex] = useState<number>(0);
	const [extend, setExtend] = useState<boolean>(true);
	const [childIndex, setChildIndex] = useState<number>(-1);
	const [showLeaderboard, setShowLeaderboard] = useState<boolean>(false);
	const [lastLeaderboard, setLastLeaderboard] = useState<boolean>(false);
	const [modalOpen, setModalopen] = useState(false);
	const history = useHistory();

	const socket = useContext(WebSocketContext)!;

	const {
		config,
		game,
		player: playerState,
	} = useAppSelector((state) => state);
	const { allPlayers } = playerState;
	const questions = game.game?.quiz.questions;
	const dispatch = useAppDispatch();

	// time calculator
	const generateTimesObject = (parentIndex: number, childIndex: number) => {
		let sectionTime: number = 0;
		let questionTime: number = 0;
		let answerTime: number = 0;
		if (questions && questions.length) {
			if (questions[parentIndex].type === "question") {
				const currentItem = questions[parentIndex] as Question;
				sectionTime = 0;
				questionTime = currentItem.questionTime;
				answerTime = currentItem.answerTime;
			} else {
				const currentItem = [...questions][parentIndex] as Section;
				if (childIndex <= 0) {
					sectionTime = currentItem.sectionTime;
					questionTime = currentItem.questions[0].questionTime;
					answerTime = currentItem.questions[0].answerTime;
				} else {
					sectionTime = 0;
					questionTime = currentItem.questions[childIndex].questionTime;
					answerTime = currentItem.questions[childIndex].answerTime;
				}
			}
		}
		return {
			sectionTime: sectionTime,
			questionTime: questionTime + 1,
			answerTime: answerTime + 1,
			total: sectionTime + questionTime + answerTime,
		};
	};

	const chooseColor = (parentIndex: number) => {
		if (lastLeaderboard) return game.game?.quiz.quizColor;
		if (questions?.[parentIndex].type === "question") {
			return game.game?.quiz.quizColor;
		} else {
			return (questions?.[parentIndex] as Section).sectionColor;
		}
	};

	const currentQuestion = (
		parentIndex: number,
		childIndex: number
	): Question => {
		if (questions?.[parentIndex].type === "section") {
			if (childIndex < 0) {
				childIndex = 0;
			}
			return [...(questions?.[parentIndex] as Section).questions][childIndex];
		}
		return questions?.[parentIndex] as Question;
	};

	const handleEndGame = async () => {
		dispatch(resetPlayerSlice());
		dispatch(resetGameSlice());
		await endGame(
			config.token,
			config.magazine.magazineId,
			game.game?._id || ""
		);

		socket.emit("endGame", { message: "", gameCode: game.game?.code });

		let linkTimer = setTimeout(() => {
			window.close();
			history.push(`/dashboard${config.queryString}`);
			clearTimeout(linkTimer);
		});
	};

	const handleRestart = () => {
		dispatch(resetAnsweredCount());
		socket.emit("restart", { message: "restart", gameCode: game.game?.code });
		history.push(
			`/game/${game.game?.code}${config.queryString}&isRestarted=yes`
		);
	};

	const handleNext = () => {
		dispatch(resetAnsweredCount());
		setAnswered({ ...defaultAnswered });
		setShowingSection(false);
		setShowingQuestions(false);
		setShowingAnswers(false);
		setShowingResult(false);
		setExtend(true);

		const isSection = (pi: number): boolean =>
			questions?.[pi].type === "section";
		if (showLeaderboard) {
			setShowLeaderboard(false);
		} else {
			if (parentIndex + 1 !== questions?.length) {
				const globalShowleaderboard = game.game?.quiz.showleaderboard;
				if (globalShowleaderboard === "after-each") {
					setShowLeaderboard(true);
					return;
				}
				if (
					globalShowleaderboard === "after-section" &&
					isSection(parentIndex) &&
					childIndex ===
						(questions?.[parentIndex] as Section).questions.length - 1
				) {
					setShowLeaderboard(true);
					return;
				}
			}
		}

		let newChildIndex: number,
			newParentIndex: number = parentIndex;
		if (isSection(parentIndex)) {
			newChildIndex = childIndex === -1 ? 1 : childIndex + 1;
			if (
				(questions?.[parentIndex] as Section).questions.length === newChildIndex
			) {
				newParentIndex = parentIndex + 1;
				if (newParentIndex === questions?.length) {
					setShowLeaderboard(true);
					setLastLeaderboard(true);
					return;
				}
				if (isSection(newParentIndex)) {
					newChildIndex = 0;
				} else {
					newChildIndex = -1;
				}
			}
		} else {
			newParentIndex = parentIndex + 1;
			if (newParentIndex === questions?.length) {
				setShowLeaderboard(true);
				setLastLeaderboard(true);
				return;
			}
			if (isSection(newParentIndex)) {
				newChildIndex = 0;
			} else {
				newChildIndex = -1;
			}
		}

		setParentIndex(newParentIndex);
		setChildIndex(newChildIndex);
		socket.emit("questionSkipNext", {
			newParentIndex,
			newChildIndex,
			gameCode: game.game?.code,
		});
	};

	useEffect(() => {
		const times = generateTimesObject(parentIndex, childIndex);
		let sectionTimer: NodeJS.Timeout,
			answerTimer: NodeJS.Timeout,
			resultTimer: NodeJS.Timeout;
		if (times.questionTime > 1) {
			if (childIndex <= 0) {
				setShowingSection(true);
				sectionTimer = setTimeout(() => {
					setShowingSection(false);
					setShowingQuestions(true);
				}, times.sectionTime * 1000);
			} else {
				setShowingQuestions(true);
			}
			answerTimer = setTimeout(() => {
				setShowingAnswers(true);
				setExtend(false);
			}, (times.sectionTime + times.questionTime) * 1000);
			resultTimer = setTimeout(() => {
				setShowingAnswers(false);
				setShowingQuestions(false);
				setShowingResult(true);
			}, (times.sectionTime + times.questionTime + times.answerTime) * 1000);
		}

		return () => {
			if (sectionTimer) {
				clearTimeout(sectionTimer);
			}
			clearTimeout(answerTimer);
			clearTimeout(resultTimer);
		};
	}, [parentIndex, childIndex]);

	useEffect(() => {
		socket.on("addPlayerToGame", (body: { sid: string }) => {
			socket.emit("onRejectJoin", {
				message: "game already started",
				gameCode: game.game?.code,
				sid: body.sid,
			});
		});
		return () => {
			socket.off("addPlayerToGame");
		};
	}, []);

	useEffect(() => {
		socket.on("setAnswerIndex", (data: { index: number; gameCode: string }) => {
			if (data.gameCode !== game.game?.code) {
				return;
			}
			const newAnswered = { ...answered };
			// @ts-ignore
			newAnswered[`${data.index + 1}`] = newAnswered[`${data.index + 1}`] + 1;
			setAnswered(newAnswered);
			dispatch(incrementAnsweredCount());
		});
		return () => {
			socket.off("setAnswerIndex");
		};
	});

	if (error) {
		return <Message type="error" text={errorMessage} />;
	}

	return (
		(questions && (
			<>
				<div className="main-screen master-screen row">
					<div className={`left px-0 ${(extend && "extend") || "col-7"}`}>
						<GameActionsPopup
							modalOpen={modalOpen}
							title={game.game?.quiz.quizName || "Game"}
							handleClose={setModalopen}
							handleEndGame={handleEndGame}
							handleRestart={handleRestart}
						/>
						<MasterHeader
							color={chooseColor(parentIndex)}
							count={countQuestions(questions)}
							enter={handle.enter}
							exit={handle.exit}
							active={handle.active}
							handleCloseModal={setModalopen}
							parentIndex={parentIndex}
							showLeaderboard={showLeaderboard}
							showingSection={showingSection}
							currentActive={currentActive(parentIndex, childIndex, questions)}
						/>
						<div
							className={`main-content d-flex justify-content-center align-items-center`}
							style={{
								background: chooseColor(parentIndex) + "CC",
							}}>
							{(showLeaderboard && (
								<Leaderboard
									lastLeaderboard={lastLeaderboard}
									showLeaderboard={showLeaderboard}
								/>
							)) ||
								(showingSection && (
									<div className="section-text">
										{(questions?.[parentIndex] as Section).sectionName ||
											"Quiz"}
									</div>
								)) ||
								(!showingSection && showingQuestions && (
									<QuestionContainer
										parentIndex={parentIndex}
										childIndex={childIndex}
										currentQuestion={currentQuestion}
										count={countQuestions(questions)}
										currentActive={currentActive(
											parentIndex,
											childIndex,
											questions
										)}
									/>
								)) ||
								(showingResult && (
									<ResultContainer
										currentActive={currentActive(
											parentIndex,
											childIndex,
											questions
										)}
										parentIndex={parentIndex}
										childIndex={childIndex}
										currentQuestion={currentQuestion}
										answered={answered}
										count={countQuestions(questions)}
										answersCount={
											currentQuestion(parentIndex, childIndex).numAnswers
										}
										color={chooseColor(parentIndex) || "#fa3846"}
									/>
								))}
						</div>
					</div>
					<div className={`right ${extend && "minimize"} col-5`}>
						<div className="timer-right">
							{(!showingResult && !showLeaderboard && (
								<PbWithTime
									time={generateTimesObject(parentIndex, childIndex).total}
								/>
							)) ||
								(lastLeaderboard && (
									<div
										className="mx-auto cursor-pointer"
										onClick={() => setModalopen(true)}>
										<VectorOff variant="#0071b3" size={48} />
									</div>
								)) || (
									<div
										className="mx-auto cursor-pointer"
										onClick={() => handleNext()}>
										<VectorNext variant="#0071b3" size={[48, 72]} />
									</div>
								)}
						</div>
						<div className="main-content">
							<div
								className={`answer-container w-100 h-100 d-flex flex-column justify-content-between`}>
								{currentQuestion(parentIndex, childIndex).answers.map(
									(answer, index) => {
										// @ts-ignore
										const color = game.game?.quiz[`answer_${index + 1}_Color`];
										const correct = showingResult && answer.correct;
										return (
											<div
												className={`answer d-flex align-items-center`}
												key={index}
												style={{ background: color + "E8" }}>
												{showingResult && !answer.correct && (
													<div className="mask"></div>
												)}
												<div
													className="answer-no d-flex align-items-center justify-content-center flex-shrink-0"
													style={{ background: (correct && "white") || color }}>
													{(correct && (
														<VectorRight variant={color} size={24} />
													)) ||
														index + 1}
												</div>
												<div className="answer-text">{answer.answer}</div>
											</div>
										);
									}
								)}
							</div>
						</div>
						<div
							className={`footer w-100 d-flex justify-content-center align-items-center ${
								extend && "minimize"
							}`}>
							ANSWERED {playerState.answeredCount} / {allPlayers.length}
						</div>
					</div>
				</div>
			</>
		)) || <p>Loading...</p>
	);
};

export default MasterScreenCompoenent;
