import { CircularProgress, TextField } from "@mui/material";
import {
	ChangeEvent,
	Dispatch,
	FunctionComponent,
	SetStateAction,
} from "react";
import PbGeneral from "../../partials/progressbar/PbGeneral";
import SliderComponent from "../../partials/SliderComponent";
import PlayerStartButton from "./PlayerStartButton";

interface JoinGameBodyPropsType {
	gettingCode: boolean;
	gettingNickName: boolean;
	waitingForOthers: boolean;
	code: string;
	nickName: string;
	onNameSubmit: React.FormEventHandler<HTMLFormElement>;
	onCodeSubmit: React.FormEventHandler<HTMLFormElement>;
	setNickName: Dispatch<SetStateAction<string>>;
	setCode: Dispatch<SetStateAction<string>>;
	error: boolean;
	errorMessgae: string;
	setError: Dispatch<SetStateAction<boolean>>;
	setErrorMessage: Dispatch<SetStateAction<string>>;
}

const JoinGameBody: FunctionComponent<JoinGameBodyPropsType> = ({
	gettingCode,
	gettingNickName,
	waitingForOthers,
	code,
	nickName,
	onNameSubmit,
	onCodeSubmit,
	setCode,
	setNickName,
	error,
	errorMessgae,
	setError,
	setErrorMessage,
}) => {
	const handleCodeChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		event.preventDefault();
		setError(false);
		setErrorMessage("");
		// below regex is to only accept the numeric values
		const regex = /^[0-9\b]+$/;
		if (event.target.value === "" || regex.test(event.target.value)) {
			const newCode = event.target?.value;
			setCode(newCode);
		}
	};

	const handleNameChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		event.preventDefault();
		setError(false);
		setErrorMessage("");
		const newName = event.currentTarget?.value;
		setNickName(newName);
	};

	const errorStyle = () => (error && "1px red solid !important") || "";

	return (
		<div
			className={`join-game-body text-center ${
				waitingForOthers &&
				"m-0 d-flex justify-content-center align-items-center"
			}`}>
			{(gettingCode && (
				<>
					<SliderComponent isIn={gettingCode}>
						<form onSubmit={(event) => onCodeSubmit(event)}>
							<TextField
								className="bg-white custom-text-field"
								placeholder="Game PIN"
								inputMode="numeric"
								autoFocus={true}
								sx={{
									input: {
										textAlign: "center",
									},
									border: errorStyle(),
								}}
								value={code}
								inputProps={{
									minLength: 4,
									maxLength: 4,
								}}
								required
								onChange={(event) => handleCodeChange(event)}
							/>
							<p
								className="text-danger mx-auto my-2"
								style={{ height: "32px", maxWidth: "360px" }}>
								{error && errorMessgae}
							</p>

							<PlayerStartButton buttonText="JOIN GAME" />
						</form>
					</SliderComponent>
				</>
			)) ||
				(gettingNickName && (
					<>
						<SliderComponent isIn={gettingNickName}>
							<form onSubmit={(event) => onNameSubmit(event)}>
								<TextField
									className="bg-white custom-text-field"
									placeholder="Nick Name"
									type="text"
									autoFocus={true}
									sx={{
										input: {
											textAlign: "center",
											letterSpacing: "normal !important",
										},
										border: errorStyle(),
									}}
									inputProps={{
										minLength: 3,
									}}
									required
									value={nickName}
									onChange={(event) => handleNameChange(event)}
								/>
								<p
									className="text-danger mx-auto my-2"
									style={{
										height: "32px",
										maxWidth: "360px",
									}}>
									{error && errorMessgae}
								</p>
								<PlayerStartButton buttonText="PLAY" />
							</form>
						</SliderComponent>
					</>
				)) ||
				(waitingForOthers && (
					<SliderComponent isIn={waitingForOthers}>
						<div className="fixed-container">
							<PbGeneral bgColor="#0071b3" />
						</div>
					</SliderComponent>
				))}
		</div>
	);
};

export default JoinGameBody;
