import { FunctionComponent } from "react";
import QRCode from "react-qr-code";
import { useAppSelector } from "../../../services/store/hooks";
import StartGameButton from "../../partials/StartGameButton";

interface PinCodeContainerPropsType {
	handleGameStart: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const PinCodeContainer: FunctionComponent<PinCodeContainerPropsType> = ({
	handleGameStart,
}) => {
	const {
		config,
		game,
		player: { allPlayers },
	} = useAppSelector((state) => state);
	const qrCodeValue = `${config.magazine.config.client?.universalLink}?code=${game.game?.code}`;

	return (
		<>
			<div className="code-container d-flex align-items-center">
				<div className="pin-container text-center">
					<div className="pin-code">
						<div className="pin-title">Game PIN:</div>
						<div className="pin">{game.game?.code}</div>
					</div>
					<div className="code-hint">Use the PIN</div>
				</div>
				<div className="or">OR</div>
				<div className="qr-code-container text-center">
					<div className="d-flex align-items-center justify-content-center qr-code">
						<QRCode value={qrCodeValue} className="code" />
					</div>
					<div className="code-hint">Use your camera</div>
				</div>
			</div>
			<StartGameButton
				handleClick={handleGameStart}
				disabled={allPlayers.length === 0}
			/>
		</>
	);
};

export default PinCodeContainer;
