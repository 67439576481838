import { FunctionComponent } from "react";

interface GameHeaderPropsType {
	color?: string;
	name?: string;
}

const GameHeader: FunctionComponent<GameHeaderPropsType> = ({
	color,
	name,
}) => {
	return (
		<div className="header" style={{ background: color || "#fa3845" }}>
			<div className="welcome">Welcome to</div>
			<div className="title">{name || "The Game"}</div>
		</div>
	);
};

export default GameHeader;
