import { FunctionComponent } from "react";

interface VectorRightPropsType {
	variant: string;
	size: number;
}

const VectorRight: FunctionComponent<VectorRightPropsType> = ({
	variant,
	size,
}) => {
	return (
		<div className="">
			<svg width="24" height="18" viewBox="0 0 24 18" fill={variant}>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M23.5607 0.43934C24.1464 1.02513 24.1464 1.97487 23.5607 2.56066L8.56066 17.5607C7.97487 18.1464 7.02513 18.1464 6.43934 17.5607L0.43934 11.5607C-0.146447 10.9749 -0.146447 10.0251 0.43934 9.43934C1.02513 8.85355 1.97487 8.85355 2.56066 9.43934L7.5 14.3787L21.4393 0.43934C22.0251 -0.146447 22.9749 -0.146447 23.5607 0.43934Z"
					fill={variant}
				/>
			</svg>
		</div>
	);
};

export default VectorRight;
