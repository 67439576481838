import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GamePlayer, Player } from "../../../interfaces/player.interface";
interface PlayerStateType {
	currentPlayer: GamePlayer | null;
	allPlayers: GamePlayer[];
	answeredCount: number;
	totalCount: number;
}

const initialState: PlayerStateType = {
	currentPlayer: null,
	allPlayers: [],
	answeredCount: 0,
	totalCount: 0,
};

export const playerSlice = createSlice({
	name: "player",
	initialState,
	reducers: {
		savePlayers(state, action) {
			state.allPlayers = action.payload;
		},
		savePlayer(state, action) {
			state.currentPlayer = action.payload;
		},
		resetAnsweredCount(state) {
			state.answeredCount = 0;
		},
		incrementAnsweredCount(state) {
			state.answeredCount = state.answeredCount + 1;
		},
		incrementTotalCount(state) {
			state.totalCount = state.totalCount + 1;
		},
		resetPlayerSlice(state) {
			state.currentPlayer = null;
			state.allPlayers = [];
		},
		saveTotalCount(state, action) {
			state.totalCount = action.payload;
		},
	},
});

export const {
	savePlayers,
	savePlayer,
	incrementTotalCount,
	resetPlayerSlice,
	saveTotalCount,
	incrementAnsweredCount,
	resetAnsweredCount,
} = playerSlice.actions;

export default playerSlice.reducer;
