import { FunctionComponent, useEffect, useState } from "react";
import "../MainScreen.scss";
import { useAppDispatch, useAppSelector } from "../../../services/store/hooks";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useParams } from "react-router-dom";
import { getGame } from "../../../services/store/features/gameSlice";
import MasterScreenComponent from "./MasterScreenComponent";
import Message from "../../layout/message/Message";

interface MainScreenPropsType {}

const MasterScreen: FunctionComponent<MainScreenPropsType> = () => {
	const [error, setError] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const { code } = useParams<{ code: string }>();
	const dispatch = useAppDispatch();

	const { config, game } = useAppSelector((state) => state);
	const handle = useFullScreenHandle();

	async function loadGame(signal: AbortSignal) {
		try {
			await dispatch(
				getGame({
					token: config.token,
					magazineId: config.magazine.magazineId,
					code: code ?? "",
					signal,
				})
			);
			setError(false);
			setErrorMessage("");
		} catch (error) {
			setError(true);
			setErrorMessage(`load game failed, error: ${error}`);
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		loadGame(controller.signal);
		return () => {
			controller.abort();
		};
	}, [code]);

	if (error) {
		return <Message text={errorMessage} type="error" />;
	}

	return (
		<FullScreen handle={handle}>
			<MasterScreenComponent handle={handle} />
		</FullScreen>
	);
};

export default MasterScreen;
