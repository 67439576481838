import { FunctionComponent } from "react";

const QbHeader: FunctionComponent<{
	title: string;
}> = ({ title }) => {
	return (
		<div className="">
			<div className="header-title">{title}</div>
			<div className="header-divider"></div>
		</div>
	);
};

export default QbHeader;
