import {
	Box,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	SelectChangeEvent,
} from "@mui/material";
import { FunctionComponent } from "react";

export interface ShowAnswerSelectorPropsType {
	value: string;
	handleShowAnswersSelect: (event: SelectChangeEvent) => void;
}

const ShowAnswerSelector: FunctionComponent<ShowAnswerSelectorPropsType> = ({
	value,
	handleShowAnswersSelect,
}) => {
	return (
		<Box className="mt-3">
			<FormControl fullWidth>
				<InputLabel id="leaderboard-select-label">show leaderboard</InputLabel>
				<Select
					labelId="leaderboard-select-label"
					value={value}
					id="demo-simple-select"
					label="showleaderboard"
					onChange={handleShowAnswersSelect}>
					<MenuItem value="after-all">end of the game</MenuItem>
					<MenuItem value="after-section">after each section</MenuItem>
					<MenuItem value="after-each">after each question</MenuItem>
				</Select>
			</FormControl>
		</Box>
	);
};

export default ShowAnswerSelector;
