import axios from "axios";

export const deletePlayer = async (
	id: string,
	signal?: AbortSignal
): Promise<any> => {
	const url = `/api/player/${id}`;
	const headers = {
		"Content-Type": "application/json",
	};
	try {
		await axios.delete(url, { headers, signal });
	} catch (error) {
		throw Error(`delete player failed, error: ${error}`);
	}
};

export const resetPlayerScore = async (
	id: string,
	signal?: AbortSignal
): Promise<any> => {
	const url = `/api/player/${id}`;
	const headers = {
		"Content-Type": "application/json",
	};
	try {
		await axios.put(url, {}, { headers, signal });
	} catch (error) {
		throw Error(`reset player scores failed, error: ${error}`);
	}
};
