import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { Section } from "../../../interfaces/quiz.interface";
import { useAppSelector } from "../../../services/store/hooks";
import StepContainer from "../../partials/StepContainer";
import FullScreen from "../../partials/vectors/full-screen";
import VectorOff from "../../partials/vectors/vector-off";

interface MasterHeaderPropsType {
	color?: string;
	count: number;
	active: boolean;
	currentActive: number;
	parentIndex: number;
	showLeaderboard: boolean;
	showingSection: boolean;
	enter: () => Promise<void>;
	exit: () => Promise<void>;
	handleCloseModal: Dispatch<SetStateAction<boolean>>;
}

const MasterHeader: FunctionComponent<MasterHeaderPropsType> = ({
	color,
	count,
	enter,
	exit,
	active,
	currentActive,
	parentIndex,
	handleCloseModal,
	showLeaderboard,
	showingSection,
}) => {
	const headerColor = color || "#2861ff";
	const { game } = useAppSelector((state) => state.game);
	const isSection: boolean =
		game?.quiz.questions[parentIndex].type === "section";
	const sectionName: string = isSection
		? (game?.quiz.questions[parentIndex] as Section).sectionName
		: "";

	return (
		<div className="header" style={{ background: headerColor + "E8" }}>
			<div
				className={`top d-flex justify-content-between align-items-center ${
					isSection && "section-header"
				}`}>
				<div className="div">
					{(showLeaderboard && (
						<>
							<div className="title-small">{game?.quiz.quizName || "Quiz"}</div>
							<div className="title">Leaderboard</div>
						</>
					)) ||
						(isSection && (
							<>
								<div className="title-small">
									{game?.quiz.quizName || "Quiz"}
								</div>
								{!showingSection && <div className="title">{sectionName}</div>}
							</>
						)) || <div className="title">{game?.quiz.quizName || "Quiz"}</div>}
				</div>
				<div className="actions d-flex align-items-center flex-shrink-0">
					<div
						className="game-pin d-flex justify-content-center align-items-center flex-column"
						style={{ background: headerColor }}>
						<div className="text">GAME PIN:</div>
						<div className="code">{game?.code}</div>
					</div>
					<div
						className="action btn btn-light d-flex align-items-center"
						onClick={active ? exit : enter}>
						<FullScreen variant={headerColor} size={24} />
					</div>
					<div
						className="action btn btn-light d-flex align-items-center"
						onClick={() => handleCloseModal(true)}>
						<VectorOff variant={headerColor} size={24} />
					</div>
				</div>
			</div>
			<StepContainer count={count} currentActive={currentActive}>
				<div className="mx-1">
					{currentActive} / {count}
				</div>
			</StepContainer>
		</div>
	);
};

export default MasterHeader;
