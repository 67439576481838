import { FunctionComponent } from "react";
import { Question } from "../../../interfaces/quiz.interface";

interface QuestionContainerPropsType {
	parentIndex: number;
	childIndex: number;
	count: number;
	currentActive: number;
	currentQuestion: (parentIndex: number, childIndex: number) => Question;
}

const QuestionContainer: FunctionComponent<QuestionContainerPropsType> = ({
	parentIndex,
	childIndex,
	count,
	currentQuestion,
	currentActive,
}) => {
	return (
		<div className="question-container remove-scroll-bar">
			<div className="question-number">
				QUESTION {currentActive} / {count}
			</div>
			<div className="question-text">
				{currentQuestion(parentIndex, childIndex).question || "no question? "}
			</div>
			{currentQuestion(parentIndex, childIndex).mediaUrl && (
				<img
					src={currentQuestion(parentIndex, childIndex).mediaUrl}
					alt="question image"
					className="question-image"
				/>
			)}
		</div>
	);
};

export default QuestionContainer;
