import { FunctionComponent } from "react";
import { Question, Section } from "../../../interfaces/quiz.interface";
import { useAppSelector } from "../../../services/store/hooks";
import Linear from "../../partials/progressbar/Linear";
import StepContainer from "../../partials/StepContainer";
import { countQuestions, currentActive } from "../../utils";
import ResultHeaderContainer from "./ResultHeaderContainer";

interface StaticHeaderPropsType {
	color: string;
	showingQuestion: boolean;
	showingAnswer: boolean;
	showingSection: boolean;
	showingResult: boolean;
	aStatus: "correct" | "wrong" | "noAnswer";
	parentIndex: number;
	childIndex: number;
	currentQuestion: Question;
	score: number;
	showLeaderboard: boolean;
}

const StaticHeader: FunctionComponent<StaticHeaderPropsType> = ({
	color,
	showingQuestion,
	showingAnswer,
	showingSection,
	parentIndex,
	childIndex,
	currentQuestion,
	showingResult,
	aStatus,
	score,
	showLeaderboard,
}) => {
	const { game } = useAppSelector((state) => state.game);
	const questions = game?.quiz.questions;
	const isSection = questions?.[parentIndex].type === "section";

	const bgColor = () => {
		if (showLeaderboard) return game?.quiz.quizColor;
		if (!showingResult) return color;
		switch (aStatus) {
			case "correct":
				return "#008738";
			case "wrong":
				return "#DB0000";
			default:
				return "#F07000";
		}
	};

	return (
		<div
			className={`play-game-header d-flex flex-column ${
				!game?.quiz.showanswers &&
				showingResult &&
				!showLeaderboard &&
				"flex-grow-1"
			} ${showingResult && aStatus}`}
			style={{ background: bgColor() }}>
			{/* showing section title with quiz title */}
			<div className="text-container flex-grow-1 d-flex align-items-center">
				<div className="text-wrapper">
					{(showLeaderboard && (
						<>
							<div className="samll-title text-uppercase">
								{game?.quiz.quizName}
							</div>
							<div className="main-title">Game finished!</div>
						</>
					)) ||
						(showingResult && (
							<ResultHeaderContainer status={aStatus} score={score} />
						)) ||
						(isSection && (
							<>
								{(!showingSection && (
									<>
										<div className="small-title text-uppercase">
											{game?.quiz.quizName || "Quiz"}
										</div>
										<div className="main-title">
											{(questions?.[parentIndex] as Section).sectionName}
										</div>
									</>
								)) || <div className="main-title">{game?.quiz.quizName}</div>}
							</>
						)) || <div className="main-title">{game?.quiz.quizName}</div>}
				</div>
			</div>
			<div className="steps d-flex align-items-center">
				{(showingResult && showLeaderboard && (
					<>
						<StepContainer
							count={countQuestions(questions)}
							currentActive={currentActive(parentIndex, childIndex, questions)}
						/>
						<div className="flex-grow-0">
							{currentActive(parentIndex, childIndex, questions)} /{" "}
							{countQuestions(questions)}
						</div>
					</>
				)) ||
					(showingSection && !showingQuestion && !showingAnswer && (
						<Linear
							blockCount={(questions?.[parentIndex] as Section).sectionTime}
						/>
					)) ||
					(!showingSection && showingQuestion && !showingAnswer && (
						<Linear blockCount={currentQuestion.questionTime} />
					)) ||
					(!showingSection && !showingQuestion && showingAnswer && (
						<Linear blockCount={currentQuestion.answerTime} />
					))}
			</div>
		</div>
	);
};

export default StaticHeader;
