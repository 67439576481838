import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useState } from "react";

export default function Linear({ blockCount }: { blockCount: number }) {
	const [progress, setProgress] = useState<number>(0);

	useEffect(() => {
		const timer = setInterval(() => {
			setProgress((oldProgress) => {
				if (oldProgress === 100) {
					return 100;
				}
				return oldProgress + 10 / blockCount;
			});
		}, 100);

		return () => {
			clearInterval(timer);
		};
	}, []);

	return (
		<Box sx={{ width: "100%" }}>
			<LinearProgress
				sx={{
					background: "rgba(255, 255, 255, 0.2) !important",
					borderRadius: "5px",
					strokeLinecap: "round",
					color: "white !important",
				}}
				variant="determinate"
				value={progress}
			/>
		</Box>
	);
}
