import {
	FormControl,
	FormHelperText,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { Section } from "../../../interfaces/quiz.interface";
import ColorPicker from "../../partials/ColorPicker";
import * as CP from "react-color";
import {
	HandleQSSelectorsFunction,
	QuestionSettingsHandlerFunction,
} from "../../../interfaces/PropTypes";
import VectorDown from "../../partials/vectors/vector-down";
import defaultSettings from "../../../interfaces/defaultSettings";

interface SectionSettingsPropsType {
	s: Section;
	setToggleSCP: Dispatch<SetStateAction<boolean>>;
	toggleSCP: boolean;
	handleSectionColor: (color: CP.ColorResult, order: number) => void;
	order: number;
	handleQSSelectors: HandleQSSelectorsFunction;
	questionSettingsHandler: QuestionSettingsHandlerFunction;
}

const SectionSettings: FunctionComponent<SectionSettingsPropsType> = ({
	s,
	setToggleSCP,
	toggleSCP,
	handleSectionColor,
	order,
	handleQSSelectors,
	questionSettingsHandler,
}) => {
	return (
		<div
			className="section-settings"
			style={{ backgroundColor: s.sectionColor + "E8" }}>
			<div className="mmd d-flex justify-content-start gap-3 align-items-center">
				<div className="quiz-color-container">
					<TextField
						label="Section background color"
						className="text-primary disableb-color custom-text-field w-100"
						id="outlined-start-adornment"
						value={s.sectionColor}
						sx={{ input: { color: "white" } }}
						InputProps={{
							endAdornment: (
								<InputAdornment
									className="toggle-color-picker h-100"
									position="end"
									onClick={() => setToggleSCP((p: boolean) => !p)}>
									<VectorDown variant="white" />
								</InputAdornment>
							),
						}}
					/>
					{toggleSCP && (
						<ColorPicker
							handleColorChange={(color) => {
								handleSectionColor(color, order);
								setToggleSCP(false);
							}}
							onMouseleave={() => setToggleSCP(false)}
						/>
					)}
				</div>
				<FormControl className="custom-text-field w-25">
					<InputLabel id="demo-simple-select-label-1">
						Time before section
					</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select-1"
						value={s.sectionTime}
						label="Time before section"
						onChange={(event) =>
							handleQSSelectors(event, "sectionTime", order)
						}>
						<MenuItem value={5}>5 Seconds</MenuItem>
						<MenuItem value={10}>10 Seconds</MenuItem>
						<MenuItem value={15}>15 Seconds</MenuItem>
						<MenuItem value={20}>20 Seconds</MenuItem>
						<MenuItem value={30}>30 Seconds</MenuItem>
					</Select>
				</FormControl>
			</div>
			<div>
				<TextField
					className="custom-text-field w-100"
					required
					label="Section name"
					value={s.sectionName}
					sx={{
						input: {
							color: "#ffffff",
						},
					}}
					onChange={(event) =>
						questionSettingsHandler(event, "sectionName", order, -1)
					}
					inputProps={{
						maxLength: defaultSettings.sectionNameLength,
					}}
				/>
				<FormHelperText className="d-flex justify-content-end px-2">
					{s.sectionName.length || 0}/{defaultSettings.sectionNameLength}
				</FormHelperText>
			</div>
		</div>
	);
};

export default SectionSettings;
