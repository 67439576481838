import { Button } from "@mui/material";
import { FunctionComponent, useState } from "react";
import { Answered, Question } from "../../../interfaces/quiz.interface";
import QuestionContainer from "./QuestionContainer";
import ResultComponent from "./ResultComponent";

interface ResultContainerPropsType {
	parentIndex: number;
	childIndex: number;
	answersCount: number;
	answered: Answered;
	color: string;
	count: number;
	currentActive: number;
	currentQuestion: (parentIndex: number, childIndex: number) => Question;
}

const ResultContainer: FunctionComponent<ResultContainerPropsType> = ({
	currentActive,
	parentIndex,
	childIndex,
	count,
	currentQuestion,
	answered,
	answersCount,
	color,
}) => {
	const [toggler, setToggler] = useState<boolean>(true);
	return (
		<>
			<Button
				className="toggle-button remove-scroll-bar"
				variant="contained"
				sx={{ color: color, background: "white" }}
				onClick={() => setToggler((p) => !p)}>
				{(toggler && "SHOW Question") || "SHOW RESULTS"}
			</Button>
			{(toggler && (
				<ResultComponent answered={answered} answersCount={answersCount} />
			)) || (
				<QuestionContainer
					currentActive={currentActive}
					parentIndex={parentIndex}
					childIndex={childIndex}
					count={count}
					currentQuestion={currentQuestion}
				/>
			)}
		</>
	);
};

export default ResultContainer;
