import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Score } from "../../../interfaces/player.interface";
import { useAppSelector } from "../../../services/store/hooks";
import { getScore } from "../../../services/GameApi";
import { WebSocketContext } from "../../../context/WebSocketContext";
import { indicator } from "ordinal";
import { Divider } from "@mui/material";

interface LeaderboardPropsType {
	lastLeaderboard: boolean;
	showLeaderboard: boolean;
}

const Leaderboard: FunctionComponent<LeaderboardPropsType> = ({
	lastLeaderboard,
	showLeaderboard,
}) => {
	const socket = useContext(WebSocketContext)!;
	const { game } = useAppSelector((state) => state.game);
	const [scores, setScores] = useState<Score[]>();
	async function getResult(gameId: string, signal: AbortSignal) {
		const newScores: Score[] = await getScore(gameId, signal);

		if (lastLeaderboard) {
			socket.emit("showLastLeaderboard", { newScores, gameCode: game?.code });
		} else {
			socket.emit("sendRank", { newScores, gameCode: game?.code });
		}
		setScores((p) => newScores);
	}

	const renderScores = (type: string) => {
		return (
			scores &&
			scores.map((score, index) => {
				const { score: point, nickName } = score;
				const rank = index + 1;
				if (index <= 2 && type === "top") {
					return (
						<div
							key={index}
							className={`rank-container r-${rank} w-100 d-flex flex-column`}>
							<div className="circle d-flex justify-content-center align-items-center">
								{rank}
								<sup>{indicator(rank)}</sup>
							</div>
							<div className="name">{nickName}</div>
							<div className="point flex-grow-1">{point} points</div>
						</div>
					);
				}
				if (index > 2 && type === "rest") {
					return (
						<div
							key={index}
							className="rank-container d-flex align-items-center">
							<Divider />
							<div className="rank text-right flex-shrink-0">
								{rank}
								<sup>{indicator(rank)}</sup>
							</div>
							<div className="name text-left flex-grow-1">{nickName}</div>
							<div className="point text-right flex-shrink-0">
								{point} points{" "}
							</div>
						</div>
					);
				}
			})
		);
	};

	useEffect(() => {
		const controller = new AbortController();
		getResult(game?._id ?? "", controller.signal);
		return () => {
			controller.abort();
		};
	}, [showLeaderboard]);

	return (
		<div className="leader-board h-100 w-100">
			<div className="row h-100">
				<div className="col-md-5 top-rank h-100 d-flex flex-column">
					{renderScores("top")}
				</div>
				<div className="col-md-7 rest-rank h-100 d-flex flex-column py-5">
					{renderScores("rest")}
					<Divider />
				</div>
			</div>
		</div>
	);
};

export default Leaderboard;
