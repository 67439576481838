import React, { FunctionComponent, useState } from "react";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useHistory } from "react-router-dom";
import { HandleQuizDeleteFunction } from "../../interfaces/PropTypes";
import * as GameApi from "../../services/GameApi";
import Message from "../layout/message/Message";
import { useAppSelector } from "../../services/store/hooks";

export interface QuizCardPropsType {
	_id?: string;
	key?: any;
	quizName: string;
	questionsCount?: number;
	handleQuizDelete: HandleQuizDeleteFunction;
}

const QuizCard: FunctionComponent<QuizCardPropsType> = ({
	_id,
	quizName,
	questionsCount,
	handleQuizDelete,
}) => {
	const history = useHistory();
	const [error, setError] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const { config } = useAppSelector((state) => state);

	const handleGameCreate = async (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		event.preventDefault();
		try {
			const data = await GameApi.createGame(
				config.token,
				config.magazine.magazineId,
				_id ?? ""
			);
			if (window.location !== window.parent.location) {
				let linkTimer = setTimeout(() => {
					window.open(`/game/${data}${config.queryString}`, "_blank");
					clearTimeout(linkTimer);
				});
				return;
			}
			history.push(`/game/${data}${config.queryString}`);
		} catch (error) {
			setError(true);
			setErrorMessage("create game failed");
		}
	};

	if (error) {
		return <Message text={errorMessage} type="error" />;
	}

	return (
		<div className="quiz-card">
			<div className="quiz-info">
				<div className="quiz-name">{quizName}</div>
				<div className="quiz-count">
					{questionsCount || 0}{" "}
					{(questionsCount && questionsCount <= 1 && "question") || "questions"}
				</div>
			</div>
			<div className="quiz-actions" id="actions">
				<Button
					variant="text"
					color="error"
					startIcon={<DeleteIcon />}
					className="quiz-action"
					onClick={(event) => handleQuizDelete(event, _id)}>
					Delete
				</Button>
				<Link to={`/edit-quiz/${_id}${config.queryString}`}>
					<Button
						variant="text"
						startIcon={<EditIcon />}
						className="quiz-action">
						Edit
					</Button>
				</Link>
				<Button
					variant="outlined"
					className="quiz-action"
					onClick={(event) => handleGameCreate(event)}>
					PLAY
				</Button>
			</div>
		</div>
	);
};

export default QuizCard;
