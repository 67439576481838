import Box from "@mui/material/Box";
import CircularProgress, {
	CircularProgressProps,
} from "@mui/material/CircularProgress";

// Inspired by the former Facebook spinners.
const PbGeneral = (props: { bgColor?: string }) => {
	return (
		<Box sx={{ position: "relative" }}>
			<CircularProgress
				variant="determinate"
				sx={{
					color: "#c5c5c5",
				}}
				size={96}
				thickness={5}
				value={100}
			/>
			<CircularProgress
				variant="indeterminate"
				sx={{
					color: props.bgColor || "black",
					borderRadius: "5px",
					animationDuration: "2000ms",
					position: "absolute",
					left: 0,
					strokeLinecap: "round",
				}}
				size={96}
				thickness={5}
			/>
		</Box>
	);
};

export default PbGeneral;
