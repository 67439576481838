import { FunctionComponent } from "react";

interface VectorOffPropsType {
	variant: string;
	size: number;
}

const VectorOff: FunctionComponent<VectorOffPropsType> = ({
	variant,
	size,
}) => {
	return (
		<div className="">
			<svg width={size} height={size} viewBox="0 0 24 24" fill="none">
				<path
					d="M10.5 10.92C10.5 11.3178 10.658 11.6994 10.9393 11.9807C11.2206 12.262 11.6022 12.42 12 12.42C12.3978 12.42 12.7794 12.262 13.0607 11.9807C13.342 11.6994 13.5 11.3178 13.5 10.92V1.5C13.5 1.10218 13.342 0.720644 13.0607 0.43934C12.7794 0.158035 12.3978 0 12 0C11.6022 0 11.2206 0.158035 10.9393 0.43934C10.658 0.720644 10.5 1.10218 10.5 1.5V10.92Z"
					fill={variant}
				/>
				<path
					d="M1.19998 11.53C0.875471 13.7977 1.27231 16.1099 2.33435 18.1396C3.39638 20.1693 5.06982 21.8136 7.11785 22.8399C9.16588 23.8661 11.4848 24.2223 13.7464 23.858C16.008 23.4937 18.0978 22.4274 19.72 20.81C20.9251 19.6131 21.8318 18.1494 22.3669 16.5374C22.9019 14.9253 23.0503 13.21 22.8 11.53C22.5506 9.82003 21.9019 8.19286 20.9064 6.78038C19.9108 5.36789 18.5965 4.20981 17.07 3.4C16.8984 3.2717 16.7014 3.18162 16.4921 3.13584C16.2829 3.09006 16.0662 3.08963 15.8568 3.13458C15.6474 3.17953 15.45 3.26881 15.2779 3.39643C15.1059 3.52405 14.9632 3.68704 14.8594 3.87443C14.7556 4.06182 14.6931 4.26927 14.6762 4.48281C14.6593 4.69636 14.6884 4.91105 14.7614 5.11243C14.8344 5.31382 14.9497 5.49723 15.0995 5.65033C15.2493 5.80343 15.4302 5.92266 15.63 6C16.7572 6.59346 17.7264 7.44753 18.4569 8.49115C19.1874 9.53476 19.6582 10.7377 19.83 12C20.0131 13.2181 19.9066 14.4622 19.5192 15.6315C19.1317 16.8007 18.4742 17.8623 17.6 18.73C16.1147 20.2151 14.1004 21.0494 12 21.0494C9.8996 21.0494 7.88524 20.2151 6.39998 18.73C5.52505 17.8627 4.8671 16.8013 4.47962 15.6319C4.09214 14.4625 3.98605 13.2181 4.16998 12C4.34072 10.7387 4.80995 9.53634 5.53869 8.4928C6.26742 7.44927 7.23464 6.59467 8.35998 6C8.65026 5.78289 8.8521 5.468 8.92816 5.11357C9.00423 4.75915 8.94937 4.38917 8.77374 4.07207C8.59812 3.75496 8.3136 3.51218 7.97281 3.38863C7.63202 3.26508 7.25802 3.26912 6.91998 3.4C5.39528 4.211 4.08296 5.3696 3.08923 6.78201C2.0955 8.19443 1.44829 9.82098 1.19998 11.53Z"
					fill={variant}
				/>
			</svg>
		</div>
	);
};

export default VectorOff;
