import { FunctionComponent } from "react";
import * as CP from "react-color";
import { defaultColors } from "../../interfaces/default-values";

export interface ColorPickerProps {
	handleColorChange: (color: CP.ColorResult) => void;
	className?: string;
	onMouseleave: () => void;
}

const ColorPicker: FunctionComponent<ColorPickerProps> = ({
	handleColorChange,
}) => {
	return (
		<CP.TwitterPicker
			colors={defaultColors}
			onChangeComplete={(color) => handleColorChange(color)}
			triangle="top-right"
			className={`mt-1`}
			width="220px"
		/>
	);
};

export default ColorPicker;
