import { textAlign } from "@mui/system";
import { FunctionComponent } from "react";

interface ReasultHeaderContainerProps {
	status: "correct" | "wrong" | "noAnswer";
	score: number;
}

const ReasultHeaderContainer: FunctionComponent<
	ReasultHeaderContainerProps
> = ({ status, score }) => {
	return (
		<div className="result-text-box d-flex flex-column justify-content-center h-100">
			{(status === "correct" && (
				<>
					<div className="main-text">You are correct!</div>
					<div className="correct-point">+{score} points</div>
				</>
			)) ||
				(status === "wrong" && (
					<div className="main-text">You got it wrong!</div>
				)) || <div className="main-text">Time's up! Too slow...</div>}
		</div>
	);
};

export default ReasultHeaderContainer;
