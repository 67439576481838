import { FunctionComponent } from "react";
import Linear from "../../partials/progressbar/Linear";

interface WaitingStartPropsType {
	gameTitle?: string;
}

const WaitingStart: FunctionComponent<WaitingStartPropsType> = ({
	gameTitle,
}) => {
	return (
		<div className="start-header join-game-header d-flex flex-column align-items-between">
			<div className="text-container d-flex flex-grow-1 justify-content-center flex-column">
				<div className="title">{gameTitle || "quiz"}</div>
				<div className="guide">The game is about to start</div>
			</div>
			<div className="step-container">
				<Linear blockCount={5} />
			</div>
		</div>
	);
};

export default WaitingStart;
