import { FunctionComponent } from "react";

interface StepContainerPropsType {
	count: number;
	currentActive: number;
	children?: JSX.Element;
}

const StepContainer: FunctionComponent<StepContainerPropsType> = ({
	count,
	currentActive,
	children,
}) => {
	return (
		<div className={`step-container d-flex align-items-center`}>
			{new Array(count).fill(0).map((e, index) => {
				return (
					<div
						key={index}
						className={`step ${currentActive > index && "active"}`}></div>
				);
			})}
			{children}
		</div>
	);
};

export default StepContainer;
