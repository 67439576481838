import { FunctionComponent } from "react";

interface VertorDoenPropsType {
	variant: string;
}

const VertorDown: FunctionComponent<VertorDoenPropsType> = ({ variant }) => {
	return (
		<div className="">
			<svg width="10" height="6" viewBox="0 0 10 6" fill="none">
				<path d="M0 0.5L5 5.5L10 0.5H0Z" fill={variant} />
			</svg>
		</div>
	);
};

export default VertorDown;
