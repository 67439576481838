import { SelectChangeEvent } from "@mui/material";
import { FunctionComponent } from "react";
import {
	HandleQSSelectorsFunction,
	MoveQuestionHandlerFunction,
	QuestionSettingsHandlerFunction,
} from "../../../interfaces/PropTypes";
import { Question } from "../../../interfaces/quiz.interface";
import AnswersContainer from "./AnswersContainer";
import QuestionHeader from "./QuestionHeader";
import QuestionSettings from "./QuestionSettings";

interface QuestionCardPropsType {
	color: string;
	order: number;
	question: Question;
	currentActive: number;
	moveQuestionHandler: MoveQuestionHandlerFunction;
	questionSettingsHandler: QuestionSettingsHandlerFunction;
	handleQSSelectors: HandleQSSelectorsFunction;
	deleteQSHandler: (index: number, nestedIndex: number) => void;
	handleCorrectAnswer: (questionIndex: number, answerIndex: number) => void;
}

const QuestionCard: FunctionComponent<QuestionCardPropsType> = ({
	color,
	order,
	moveQuestionHandler,
	question,
	questionSettingsHandler,
	handleQSSelectors,
	deleteQSHandler,
	handleCorrectAnswer,
	currentActive,
}) => {
	return (
		<div className="question-container">
			<QuestionHeader
				color={color}
				order={order}
				moveQuestionHandler={moveQuestionHandler}
				deleteQSHandler={deleteQSHandler}
				currentActive={currentActive}
			/>
			<QuestionSettings
				color={color}
				question={question}
				questionSettingsHandler={questionSettingsHandler}
				order={order}
				handleQSSelectors={handleQSSelectors}
			/>
			<AnswersContainer
				color={color}
				question={question}
				handleQSSelectors={handleQSSelectors}
				order={order}
				questionSettingsHandler={questionSettingsHandler}
				handleCorrectAnswer={handleCorrectAnswer}
			/>
		</div>
	);
};

export default QuestionCard;
