import { Button } from "@mui/material";
import { FunctionComponent, MouseEvent, useState } from "react";
import { Question, Section } from "../../../interfaces/quiz.interface";
import { useAppSelector } from "../../../services/store/hooks";
import VectorRight from "../../partials/vectors/vector-right";
import { currentActive } from "../../utils";

interface AnswersContainerPropsType {
	currentQuestion: Question;
	parentIndex: number;
	childIndex: number;
	showingResult: boolean;
	flexGrow?: string;
	handleScore: (
		t1: number,
		scored: boolean,
		questionIndex: number,
		index: number
	) => void;
}

const AnswersContainer: FunctionComponent<AnswersContainerPropsType> = ({
	currentQuestion,
	parentIndex,
	childIndex,
	showingResult,
	handleScore,
	flexGrow,
}) => {
	const { game } = useAppSelector((state) => state.game);
	const [disabled, setDisabled] = useState<boolean>(showingResult);
	const [selected, setSelected] = useState<number>(-1);

	const handleClick = async (
		event: MouseEvent,
		index: number,
		correct: boolean
	) => {
		event.preventDefault();
		await handleScore(
			Date.now(),
			correct,
			currentActive(parentIndex, childIndex, game!.quiz.questions!),
			index
		);
		setDisabled(true);
		setSelected(index);
	};
	return (
		<div className={`main-content d-flex flex-column ${flexGrow || ""}`}>
			{currentQuestion.answers.map((answer, index) => {
				// @ts-ignore
				const color = game.quiz[`answer_${index + 1}_Color`];
				const correct = showingResult && answer.correct;
				const noDisplay = showingResult && !game?.quiz.showanswers && !correct;
				const unSelected =
					!showingResult && index !== selected && selected !== -1;
				return (
					<div
						className={`answer-wrapper ${
							((unSelected || (showingResult && !answer.correct)) && "wrong") ||
							""
						} ${noDisplay && "d-none"}`}
						key={index}>
						<div
							className={`answer  d-flex align-items-center justify-content-start ${
								disabled && "disabled"
							}`}
							style={{ background: correct ? "green" : color + "E8" }}
							onClick={(event) =>
								handleClick(event, index, answer.correct || false)
							}>
							<div
								className="answer-no d-flex align-items-center justify-content-center flex-shrink-0"
								style={{ background: (correct && "white") || color }}>
								{(correct && <VectorRight variant={"green"} size={24} />) ||
									index + 1}
							</div>
							<div className="anwer-text">{answer.answer}</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default AnswersContainer;
